import React from 'react';
import CheckBox from '../common/CheckBox';
import FSLabel from './FSLabel';

const ActionEmails = ({ action, data, handleEmailActionChecked, customerID, notificationTypes }) => {
    const labelText = data => {
        switch (data.action) {
            case 'Statements':
            case 'Additional Contact':
                return data.action;
            case 'Extensions':
                return data.user;
            case 'Email Gateway':
                return data.user;
            case 'Notification':
                return data.emailNotificationDesc;
            case 'Tickets':
                return 'Jivetel Ticket System';
            case 'FS User':
                return <FSLabel dids={data.diDs} />;
            default:
                return data.action;
        }
    };

    const onEmailActionChecked = e => {
        handleEmailActionChecked(e.target.id, e.target.attributes.typeID?.value ?? '');
    };
    const notificationChecboxes = notificationTypes?.map((n, i) => {
        const typeData = data.find(d => d.emailNotificationTypeID === n.id);
        return <CheckBox
            key={i}
            onChange={onEmailActionChecked}
            label={n.name}
            checked={typeData ? typeData.subscribed : false}
            id={typeData ? typeData.uniqueID : `newnt${n.id}${customerID}`}
            wrapperClass='text-left'
            name={typeData ? typeData.uniqueID : `newnt${n.id}${customerID}`}
            customAttributes={{ typeid: n.id }}
        />;
    });

    let emailList;
    if (action === 'Notification') {
        emailList = notificationChecboxes;
    } else if (action === 'Statements') {
        const statementData = data[0];
        emailList = [<CheckBox
            key='statements'
            onChange={onEmailActionChecked}
            label={labelText(statementData)}
            checked={statementData.subscribed}
            id={statementData.uniqueID}
            wrapperClass='text-left'
            name={statementData.uniqueID}
        />,
        <CheckBox
            key='receipts'
            onChange={onEmailActionChecked}
            label='Receipts'
            checked={statementData.sendReceipt && statementData.subscribed}
            id='receipts'
            wrapperClass='text-left'
            name='receipts'
            disabled={!statementData.subscribed}
            customAttributes={{ typeid: statementData.uniqueID }}
        />];
    } else {
        emailList = data.map((d, i) => <CheckBox
            key={i}
            onChange={onEmailActionChecked}
            label={labelText(d)}
            checked={d.subscribed}
            id={d.uniqueID}
            wrapperClass={d.action === 'FS User' ? 'd-flex text-left' : 'text-left'}
            name={d.uniqueID}
        />);
    }

    return (
        <>
            <div className="row col-12 border-bottom mb-2">
                <h5>{action}</h5>
                <div className="col-12">
                    {emailList}
                </div>
            </div>
        </>
    );
};

export default ActionEmails;
