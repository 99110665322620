import React from 'react';
import TopNavWrapper from './TopNavWrapper';
import JivetelNav from './JivetelNav';

const Header = () => {
  return (
        <>
            <TopNavWrapper />
            <JivetelNav pageSection="header" />
        </>
  );
};

export default Header;
