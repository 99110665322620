import React from 'react';
import JiveCard from '../common/JiveCard';

const EmptyList = () => {
    return (
        <JiveCard>
            <p>You are currently not subscribed to any Jivetel Emails.</p>
            <p>If you feel this is a error please reach out to customer service.</p>
        </JiveCard>
    );
};

export default EmptyList;
