import React from 'react';

const FSLabel = ({ dids }) => {
    return (
        <div className="border p-1">
            {dids.map((did, i) => <React.Fragment key={i}>{did}<br /></React.Fragment>)}
        </div>
    );
};

export default FSLabel;
