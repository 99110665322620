import React, { useState, useEffect } from 'react';
import { FormatCurrency } from '../common/FormatCurrency';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Table from 'react-bootstrap/Table';

const ReviewingChargesDisplay = ({ isRecurring, title, onTotalUpdate }) => {
    const [charges, setCharges] = useState([]);
    const [showDiscount, setShowDiscount] = useState(false);
    const { quote: { reviewingContract, allowCustomerToEdit } } = useReviewQuoteContext();
    const urlQuoteID = useParams().quoteID;

    useEffect(() => {
        getQuoteCharges();
    }, [urlQuoteID]);

    useEffect(() => {
        const filteredCharges = charges.filter(c => !c.onPaymentPlan).reduce((prev, current) => prev + current.subTotal, 0);
        const sum = charges.reduce((prev, current) => prev + current.subTotal, 0);
        const tax = charges.reduce((prev, current) => prev + current.salesTax, 0);
        onTotalUpdate({ subTotal: sum, tax, filteredCharges });
    }, [charges]);

    const getQuoteCharges = async () => {
        const { data } = await axios.post('/Api/Quotes/PostToCore?route=/API/Quotes/GetQuoteCharges', { quoteID: urlQuoteID, recurring: isRecurring, userFacing: true });
        const discount = data.charges.reduce((prev, current) => prev + current.discountAmount, 0);
        setShowDiscount(discount > 0);
        const chargesToShow = data.charges.filter(c => !(c.unitPrice === 0 && c.primaryChargeID)).sort((a, b) => a.rowIndex - b.rowIndex);
        setCharges(chargesToShow);
    };

    const discountDisplay = (data) => {
        if (data.discountAmount > 0) {
            if (data.discountType === 'Percent') {
                return `${data.discountPercent}%`;
            }
            return FormatCurrency(data.discountAmount);
        }
        return '';
    };

    const renderCells = (data, val, qty) => {
        if (data.equipmentID === 'COMMENT_MRC' || data.equipmentID === 'COMMENT_NRC') {
            return <div></div>;
        }
        return qty ? val.toString() : FormatCurrency(val);
    };

    return (<>
        <div>
            <h5> <b>{title}</b></h5>
            <Table borderless className='mb-0'>
                <thead className='border-bottom'>
                    <tr className='gray-text text-right'>
                        <th className='font-weight-normal text-left'>Description</th>
                        <th className='font-weight-normal'>Price</th>
                        <th className='font-weight-normal'>QTY</th>
                        {showDiscount && <th className='font-weight-normal'>Discount</th>}
                        <th className='font-weight-normal'>Subtotal</th>
                        <th className='font-weight-normal'>Tax</th>
                        <th className='font-weight-normal'>Total</th>
                    </tr>
                </thead>
                <tbody className='semi-bold border-bottom'>
                    {charges.map(c => {
                        return <tr key={c.quoteChargeID} className='text-right'>
                            <td className='text-left'>{c.description}</td>
                            <td>{renderCells(c, c.unitPrice)}</td>
                            <td>{renderCells(c, c.quantity, true)}</td>
                            {showDiscount && <td>{discountDisplay(c)}</td>}
                            <td>{renderCells(c, c.subTotal)}</td>
                            <td>{renderCells(c, c.salesTax)}</td>
                            <td>{renderCells(c, c.total)}</td>
                        </tr>;
                    })}
                </tbody>
                <tfoot>
                    <tr className='gray-text text-right'>
                        <td className='text-left'>Totals:</td>
                        <td colSpan={showDiscount ? '4' : '3'}>{FormatCurrency(charges.reduce((prev, current) => prev + current.subTotal, 0))}</td>
                        <td>{FormatCurrency(charges.reduce((prev, current) => prev + current.salesTax, 0))}</td>
                        <td>{FormatCurrency(charges.reduce((prev, current) => prev + current.total, 0))}</td>
                    </tr>
                </tfoot>
            </Table>
        </div>
    </>);
};

export default ReviewingChargesDisplay;
