/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { states, provinces, countries } from '../common/States';
import axios from 'axios';
import InputGroup from 'react-bootstrap/InputGroup';
import Alert from 'react-bootstrap/Alert';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';
import InlineText from '../common/InlineText';
import ACHDisplay from './ACHDisplay';
import CreditCardDisplay from './CreditCardDisplay';
import { notify, objectMatches } from '../../helper';
import IframeApp from './CreditCardCloverIframe';
import { LoadPanel } from 'devextreme-react/load-panel';

const PaymentInfo = ({ quoteID, formRef, buttonRef }) => {
    const [paymentType, setPaymentType] = useState(0);
    const [billingAddress, setBillingAddress] = useState({});
    const [creditCardInfo, setCreditCardInfo] = useState({ type: 'CreditCard', quoteID, creditCardToken: '', creditCardType: 'visa' });
    const [origCreditCardInfo, setOrigCreditCardInfo] = useState({ type: 'CreditCard', quoteID, creditCardToken: '', creditCardType: 'visa' });
    const [achPaymentInfo, setAchPaymentInfo] = useState({ type: 'ACH', quoteID });
    const [disabled, setDisabled] = useState(false);
    const [stateOrProvince, setStateOrProvince] = useState(states);
    const [validated, setValidated] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMsg, setAlertMsg] = useState('');
    const [error, setError] = useState({});
    const [showPaymentModal, setshowPaymentModal] = useState(false);
    const { reviewingContract, generatingPDF, quote } = useReviewQuoteContext();
    const [showLoadPanel, setShowLoadPanel] = useState(false);

    useEffect(() => {
        getQuotePaymentInformation();
    }, []);

    useEffect(() => {
        setPaymentType(quote.paymentMethod || '');
    }, [quote.paymentMethod]);

    useEffect(() => {
        buttonRef.current = {
            click: (e, result, billingContactEmail, serviceAddressZip) => onSaveButtonClick(e, result, billingContactEmail, serviceAddressZip)
        };
    }, [buttonRef, creditCardInfo, achPaymentInfo, paymentType, quoteID, origCreditCardInfo]);

    const getQuotePaymentInformation = async () => {
        const { data } = await axios.post(`/Api/Quotes/PostToCore?route=/API/Quotes/GetQuotePaymentInformation/?quoteID=${quoteID}`);
        if (data.result) {
            if (data.result.paymentType === 'ACH') {
                setAchPaymentInfo({ ...data.result, quoteID });
            } else {
                setCreditCardInfo({ ...data.result, quoteID });
                setOrigCreditCardInfo({ ...data.result, quoteID });
            }
        }
    };

    const onPaymentTypeSelected = (e) => {
        setPaymentType(e.target.value);
    };

    const onBillingAddressChecked = (e) => {
        if (e.target.checked) {
            console.log('checked');
        }
    };

    const onCountryChanged = (e) => {
        setBillingAddress({ ...billingAddress, [e.target.name]: e.target.value });

        if (e.target.value !== 'UNITED STATES' && e.target.value !== 'CANADA') {
            setDisabled(true);
        } else if (e.target.value === 'CANADA') {
            setStateOrProvince(provinces);
            setDisabled(false);
        } else {
            setStateOrProvince(states);
            setDisabled(false);
        }
    };

    const onPaymentInfoChanged = (e) => {
        if (!e.target.value.match(e.target.pattern)) {
            setError({ ...error, [e.target.name]: 'Please enter a valid number' });
        } else {
            if (Object.prototype.hasOwnProperty.call(error, 'e.target.name')) {
                delete error.e.target.name;
            }
        }

        if (paymentType === 'ACH') {
            setAchPaymentInfo({ ...achPaymentInfo, [e.target.name]: e.target.value });
        } else if (paymentType === 'CreditCard') {
            setCreditCardInfo({ ...creditCardInfo, [e.target.name]: e.target.value });
        }
    };

    const getMonths = () => {
        const months = [];
        for (let i = 1; i < 13; i++) {
            months.push(i.toString().padStart(2, 0));
        }
        return months;
    };

    const getYears = () => {
        const years = [];
        for (let i = 0; i < 6; i++) {
            const year = new Date().getFullYear() + i;
            years.push(year);
        }
        return years;
    };

    const onSaveButtonClick = async (e, result, billingContactEmail, serviceAddressZip) => {
        const ignore = (paymentType === 'CreditCard' && origCreditCardInfo.creditCardToken && objectMatches(creditCardInfo, origCreditCardInfo)) ||
            (!result.sign && !paymentType);
        if (ignore) {
            result.validData = true;
            result.ignore = true;
            return;
        }
        if (formRef.current.checkValidity() === true) {
            setShowLoadPanel(true);
            if (paymentType === 'ACH') {
                const { data } = await axios.post('/Api/Quotes/PostToCore?route=/API/Quotes/SaveQuoteACHInformation', achPaymentInfo);
                if (data.result) {
                    setAchPaymentInfo({ ...achPaymentInfo, bankName: data.bankName });
                    result.validData = true;
                } else {
                    result.validData = false;
                }
            } else if (paymentType === 'CreditCard') {
                const paymentInfo = {
                    creditCard: {
                        number: creditCardInfo.creditCardNumber,
                        exp_month: creditCardInfo.creditCardExpiryMonth,
                        exp_year: creditCardInfo.creditCardExpiryYear
                        // address_line1: billingAddress.address_1,
                        // address_2: billingAddress.address_2,
                        // address_city: billingAddress.city,
                        // address_state: billingAddress.state,
                        // address_zip: billingAddress.zip,
                        // address_country: billingAddress.country
                    },
                    token: creditCardInfo.creditCardToken,
                    serviceAddressZip,
                    name: creditCardInfo.accountHolderName,
                    email: billingContactEmail
                };
                const { data } = await axios.post('Api/Quotes/PostToCore?route=/API/Core/CreateCCTokenNonCustomer', { ...paymentInfo });
                if (data.result && data.tokenResult) {
                    setShowAlert(false);
                    const updatedInfo = {
                        ...creditCardInfo,
                        creditCardToken: data.tokenResult
                    };
                    console.log(paymentInfo);
                    setCreditCardInfo(oldState => ({
                        ...oldState,
                        creditCardToken: data.tokenResult
                    }));
                    const { data: ccData } = await axios.post('Api/Quotes/PostToCore?route=/API/Quotes/SaveQuoteCCInformation', updatedInfo);
                    result.validData = true;
                } else {
                    result.validData = false;
                    setAlertMsg(data.msg);
                    setShowAlert(true);
                    setCreditCardInfo({});
                }
            } else {
                result.validData = true;
            }
            const data = await updatePaymentMethod();
            if (!data.result) {
                result.validData = false;
                notify(data.message, 'error');
            }
            setShowLoadPanel(false);
        } else {
            setValidated(true);
        }
    };

    const updatePaymentMethod = async () => {
        const { data } = await axios.post('Api/Quotes/PostToCore?route=/API/Quotes/UpdatePaymentMethod', { quoteID, paymentMethod: paymentType });
        return data;
    };

    const onTokenization = (result) => {
        setshowPaymentModal(false);
        setCreditCardInfo({
            accountHolderName: result.name,
            creditCardNumber: result.card.last4,
            creditCardExpiryMonth: result.card.exp_month,
            creditCardExpiryYear: result.card.exp_year,
            creditCardToken: result.token,
            creditCardType: result.card.brand,
            quoteID
        });
    };

    const onAddCrediCardClick = () => {
        setshowPaymentModal(true);
    };

    const showInputs = !reviewingContract && !generatingPDF;

    return (
        <div className='pb-3 avoid-page-break'>
            <h5><b>Payment Information</b></h5>
            {(showAlert && paymentType === 'CreditCard') &&
                <Row className='pt-2'>
                    <Col xs={12} md={6} lg={3}>
                        <Alert variant='warning'>{alertMsg}</Alert>
                    </Col>
                </Row>
            }
            <Form noValidate validated={validated} onSubmit={onSaveButtonClick} ref={formRef}>
                {showInputs && <Form.Group>
                    <Row className='pt-2'>
                        <Col xs={12} md={6} lg={3}>
                            <Form.Control tabIndex={27} as="select" name="select" id="selectPaymentType" onChange={onPaymentTypeSelected} value={paymentType} required>
                                <option hidden value="">Select Payment Type</option>
                                <option value="ACH">ACH</option>
                                <option value="CreditCard">Credit Card</option>
                                {quote.quoteHash?.allowChecks && <option value="Check">Check</option>}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">This is a required field</Form.Control.Feedback>
                        </Col>
                    </Row>
                </Form.Group>}
                {!showInputs && <>
                    <InlineText label='Payment Type' value={paymentType} />
                </>}

                {paymentType === 'ACH' && <>
                    {showInputs
                        ? <Form.Group className='mt-2'>
                            <Row>
                                <Col xs={12} md={6} lg={3} className='mb-3'>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control name="accountHolderName" id="accountName" placeholder="Name on Account" onChange={onPaymentInfoChanged} required defaultValue={achPaymentInfo.accountHolderName} />
                                    <Form.Control.Feedback type="invalid">This is a required field</Form.Control.Feedback>
                                </Col>
                                <Col xs={12} md={6} lg={3} className='mb-3'>
                                    <Form.Label>Account Number</Form.Label>
                                    <Form.Control name="accountNumber" id="accountNumber" placeholder="Account Number" pattern="^[0-9]*$" onChange={onPaymentInfoChanged} required defaultValue={achPaymentInfo.accountNumber} />
                                    <Form.Control.Feedback type="invalid"> {Object.prototype.hasOwnProperty.call(error, 'accountNumber') ? error.accountNumber : 'This is a required field'}</Form.Control.Feedback>
                                </Col>
                                <Col xs={12} md={6} lg={3} className='mb-3'>
                                    <Form.Label>Routing Number</Form.Label>
                                    <Form.Control name="routingNumber" id="routingNumber" placeholder="Routing Number" pattern="^[0-9]{9}$" onChange={onPaymentInfoChanged} required defaultValue={achPaymentInfo.routingNumber} />
                                    <Form.Text> {achPaymentInfo.bankName}</Form.Text>
                                    <Form.Control.Feedback type="invalid">{Object.prototype.hasOwnProperty.call(error, 'routingNumber') ? error.routingNumber : 'This is a required field'}</Form.Control.Feedback>
                                </Col>
                            </Row>
                        </Form.Group>
                        : <ACHDisplay info={achPaymentInfo} />}</>}
                {paymentType === 'CreditCard' && <>{showInputs && !creditCardInfo.creditCardToken
                    ? <div className="mt-5 d-flex justify-content-start">
                        <button type="button" className="btn-blue large-button" onClick={onAddCrediCardClick}><span className='white-text'>Add Credit Card</span></button>
                    </div>
                    : <CreditCardDisplay info={creditCardInfo} />}
                </>}
                {showInputs && <button type="submit" className='m-3 btn-blue extra-small-button' ref={buttonRef} hidden>
                    <span className='white-text'>Save</span>
                </button>}
            </Form>
            <IframeApp show={showPaymentModal} onClose={() => setshowPaymentModal(false)} onTokenization={onTokenization} />
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position='center'
                message='Saving Payment Info..'
                visible={showLoadPanel}
                showIndicator={true}
                showPane={true}
            />
        </div>
    );
};
export default PaymentInfo;
