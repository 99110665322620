import React from 'react';

const Submit = ({ handleSubmit }) => {
    const onSubmit = () => {
        handleSubmit();
    };
    return (
        <button className="btn btn-lg btn-success" onClick={onSubmit}>Submit</button>
    );
};

export default Submit;
