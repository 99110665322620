import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import { getEmptyLocation } from '../../helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Radio from '@mui/material/Radio';
import { LoadPanel } from 'devextreme-react/load-panel';

const AddressValidationModal = ({ myAddresses, show, onClose, onContinueCallBack }) => {
    const [addresses, setAddresses] = useState([]);
    const [apiDown, setApiDown] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (show) {
            validateAddresses();
        } else {
            setIsLoading(true);
        }
    }, [show]);

    const onContinueClick = () => {
        onClose();
        onContinueCallBack(addresses.map(a => {
            const targetAddress = a.choice === 'myAddress' ? a.myAddress : a.systemAddress;
            const zipCode = targetAddress.zipCode;
            return {
                ...targetAddress,
                addressType: a.myAddress.addressType,
                zipCode: zipCode.includes('-') ? zipCode.substring(0, zipCode.indexOf('-')) : zipCode
            };
        }));
    };

    const onRadioButtonChange = (e, addressType) => {
        setAddresses(addresses.map(a => {
            if (addressType === a.myAddress.addressType) {
                a.choice = e.target.value;
            }
            return a;
        }));
    };

    const validateAddresses = async () => {
        const { data } = await axios.post('/Api/Quotes/PostToCore?route=/API/Addresses/SearchAddresses', myAddresses);
        setApiDown(data.results.some(r => !r.result));
        if (data.results.some(r => r.prompt)) {
            setAddresses(data.results.map(ma => {
                return {
                    myAddress: ma.myAddress,
                    choice: ma.validAddressy ? 'system' : 'myAddress',
                    systemAddress: ma.address,
                    addresssFound: ma.validAddressy
                };
            }));
        } else if (data.results.every(r => r.result)) {
            onClose();
            onContinueCallBack(data.results.map(a => {
                const targetAddress = a.address;
                const zipCode = targetAddress.zipCode;
                return {
                    ...targetAddress,
                    addressType: a.myAddress.addressType,
                    zipCode: zipCode.includes('-') ? zipCode.substring(0, zipCode.indexOf('-')) : zipCode
                };
            }));
        }
        setIsLoading(false);
    };

    const getAddressType = (value) => {
        if (!+value && value !== 0) {
            return value;
        } else if (value === 0) {
            return 'Billing';
        } else if (value === 2) {
            return 'Service';
        }
    };

    return (<>
        {!isLoading && <Modal show={show}
            enforceFocus={false}
            onHide={onClose}
            className='modal-dark-bg'
            centered>
            <Modal.Header className='bg-primary-600'>
                <Modal.Title>Select Address</Modal.Title>
                <button type="button" className='close' onClick={onClose}>
                    <FontAwesomeIcon icon='fa-times' />
                </button>
            </Modal.Header>
            <Modal.Body>
                {addresses.map((a, i) => <div className={i < addresses.length - 1 ? 'border-bottom mb-3' : ''} key={a.myAddress.addressType}>
                    <h6 className='pl-3'>{`${getAddressType(a.myAddress.addressType)} Address:`}</h6>
                    <div className='row' >
                        <div className="col-lg-1"></div>
                        <div className="col-lg-5">
                            <div className="form-label">Use my address</div>
                            <Radio
                                checked={a.choice === 'myAddress'}
                                onChange={(e) => onRadioButtonChange(e, a.myAddress.addressType)}
                                value='myAddress'
                                name='myAddress'
                                inputProps={{ 'aria-label': 'A' }}
                            />
                            <address id='usersAddress'>
                                <div>{a.myAddress.address1?.toUpperCase()}
                                    {a.myAddress.address2 ? ', ' + a.myAddress.address2?.toUpperCase() : ''}
                                    <br />{a.myAddress.city?.toUpperCase() || ''}
                                    {(a.myAddress.state
                                        ? ', ' + a.myAddress.state?.toUpperCase()
                                        : '') + ' '}
                                    {a.myAddress.zipCode?.toUpperCase() || ''}
                                    <br />{a.myAddress.country?.toUpperCase() || ''}
                                    <br />
                                </div>
                            </address>
                        </div>
                        <div className='col-lg-5 col-xs-margin-top-10'>
                            <div className='form-label'>Use address found</div>
                            {a.addresssFound
                                ? <Radio
                                    checked={a.choice === 'system'}
                                    onChange={(e) => onRadioButtonChange(e, a.myAddress.addressType)}
                                    value='system'
                                    name='system'
                                    inputProps={{ 'aria-label': 'B' }}
                                />
                                : <div className='p-2'></div>}
                            <address id="systemAddress">
                                {a.addresssFound &&
                                    <div>{a.systemAddress.address1?.toUpperCase()}
                                        {a.systemAddress.address2 ? ', ' + a.systemAddress.address2?.toUpperCase() : ''}
                                        <br />{a.systemAddress.city?.toUpperCase() || ''}
                                        {(a.systemAddress.state
                                            ? ', ' + a.systemAddress.state?.toUpperCase()
                                            : '') + ' '}
                                        {a.systemAddress.zipCode?.toUpperCase() || ''}
                                        <br />{a.systemAddress.country?.toUpperCase() || ''}
                                        <br />
                                    </div>}
                                <div>{apiDown
                                    ? <span className='form-label'>External API is not responding</span>
                                    : !a.addresssFound
                                        ? 'We could not find a address based on the info you provided do you wish to continue using your address'
                                        : ''}</div>
                            </address>
                        </div>
                    </div>
                </div>
                )}
            </Modal.Body>
            <Modal.Footer>
                <button type='button' className='btn btn-sm btn-default' onClick={onClose}>Close</button>
                <button className='btn btn-blue' onClick={onContinueClick}>
                    <span className='white-text'>Continue</span>
                </button>
            </Modal.Footer>
        </Modal>}
        <LoadPanel
            shadingColor="rgba(0,0,0,0.4)"
            position='center'
            message='Validating addresses...'
            visible={show && isLoading}
            showIndicator={true}
            showPane={true}
        />
    </>
    );
};

export default AddressValidationModal;
