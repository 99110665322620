import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import useScript from '../../hooks/useScript';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CreditCardCloverIframe = ({ show, onTokenization, onClose }) => {
    useScript('https://checkout.clover.com/sdk.js');
    const [cloverIframe, setCloverIframe] = useState(0);
    const [cardResponseEl, setcardResponseEl] = useState(0);
    const [cardName, setCardName] = useState('');
    const [footer, setFooter] = useState(null);

    const styles = {
        body: {
            fontFamily: 'Roboto, Open Sans, sans-serif',
            fontSize: '16px'
        },
        input: {
            fontSize: '13px',
            border: '1px solid #aaa',
            height: '50px',
            borderRadius: '10px',
            padding: '8px'
        },
        img: {
            right: '10px !important',
            top: '10px !important'
        }
    };
    useEffect(() => {
        if (show) {
            const clover = new window['Clover']('51378e19744e03ac3029a3e24adc0d17');
            // clover = new C('12a3b456789c12345d67891234e56f78');
            const elements = clover.elements();
            setCloverIframe(clover);
            const form = document.getElementById('payment-form');
            const cardNumber = elements.create('CARD_NUMBER', styles);
            const cardDate = elements.create('CARD_DATE', styles);
            const cardCvv = elements.create('CARD_CVV', styles);
            const cardPostalCode = elements.create('CARD_POSTAL_CODE', styles);

            cardNumber.mount('#card-number');
            cardDate.mount('#card-date');
            cardCvv.mount('#card-cvv');
            cardPostalCode.mount('#card-postal-code');

            const cardResponse = document.getElementById('card-response');
            const displayCardNumberError = document.getElementById('card-number-errors');
            const displayCardDateError = document.getElementById('card-date-errors');
            const displayCardCvvError = document.getElementById('card-cvv-errors');
            const displayCardPostalCodeError = document.getElementById('card-postal-code-errors');
            setcardResponseEl(cardResponse);

            // Handle real-time validation errors from the card element
            cardNumber.addEventListener('change', function (event) {
                console.log(`cardNumber changed ${JSON.stringify(event)}`);
                displayCardNumberError.innerHTML = event.CARD_NUMBER.error || '';
            });

            cardNumber.addEventListener('blur', function (event) {
                console.log(`cardNumber blur ${JSON.stringify(event)}`);
            });

            cardDate.addEventListener('change', function (event) {
                console.log(`cardDate changed ${JSON.stringify(event)}`);
                displayCardDateError.innerHTML = event.CARD_DATE.error || '';
            });

            cardDate.addEventListener('blur', function (event) {
                console.log(`cardDate blur ${JSON.stringify(event)}`);
            });

            cardCvv.addEventListener('change', function (event) {
                console.log(`cardCvv changed ${JSON.stringify(event)}`);
                displayCardCvvError.innerHTML = event.CARD_CVV.error || '';
            });

            cardCvv.addEventListener('blur', function (event) {
                console.log(`cardCvv blur ${JSON.stringify(event)}`);
            });

            cardPostalCode.addEventListener('change', function (event) {
                console.log(`cardPostalCode changed ${JSON.stringify(event)}`);
                displayCardPostalCodeError.innerHTML = event.CARD_POSTAL_CODE.error || '';
            });

            cardPostalCode.addEventListener('blur', function (event) {
                console.log(`cardPostalCode blur ${JSON.stringify(event)}`);
            });
            const cloverFooter = document.querySelector('div.clover-footer');
            if (cloverFooter) {
                // Clone the footer element
                const clonedFooter = cloverFooter.cloneNode(true);
                // Set the cloned footer to state
                setFooter(clonedFooter);
                // Remove original footer
                cloverFooter.remove();
            }
        }
    }, [show]);
    const onSubmit = (e) => {
        cloverIframe.createToken()
            .then(function (result) {
                if (result.errors) {
                    Object.values(result.errors).forEach(function (value) {
                        cardResponseEl.textContent = value;
                    });
                } else {
                    result.name = cardName;
                    onTokenization(result);
                }
            });
    };
    const handleClose = () => {
        onClose();
    };
    return (<>
        <Modal show={show} enforceFocus="false" className='modal-dark-bg' centered>
            <Modal.Header>
                <Modal.Title>Add Credit Card</Modal.Title>
                <button type="button" className='close' onClick={onClose}>
                    <FontAwesomeIcon icon='fa-times' />
                </button>
            </Modal.Header>
            <Modal.Body className=''>
                <div className="mt-2" id="clover-payment-form">
                    <form id="payment-form">
                        <div className="row mb-2">
                            <div className="col-12 form-group">
                                <input className="form-control" placeholder="Name on card" value={cardName} onInput={(e) => setCardName(e.target.value)} />
                            </div>
                        </div>
                        <div className="top-row">
                            <div className="input-errors" id="card-number-errors" role="alert"></div>

                            <div id="card-number" className="field card-number"></div>
                        </div>

                        <div className="top-row">
                            <div className="input-errors" id="card-date-errors" role="alert"></div>
                            <div id="card-date" className="field third-width"></div>
                        </div>

                        <div className="top-row">
                            <div className="input-errors" id="card-cvv-errors" role="alert"></div>
                            <div id="card-cvv" className="field third-width"></div>
                        </div>

                        <div className="top-row">
                            <div className="input-errors" id="card-postal-code-errors" role="alert"></div>
                            <div id="card-postal-code" className="field third-width"></div>
                        </div>

                        <div id="card-response" role="alert"></div>
                    </form>
                    {footer && <div id="modal-footer-copy" dangerouslySetInnerHTML={{ __html: footer.outerHTML }} />}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" form="payment-form" onClick={onSubmit}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    </>
    );
};
export default CreditCardCloverIframe;
