import React from 'react';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';
import TextDisplay from '../common/TextDisplay';

const CompanyDisplay = ({ company }) => {
    const { preApproval } = useReviewQuoteContext();
    return (
        <dl>
            <TextDisplay
                value={company.companyName} // company name is required to create the quote
                label='Company Name' />
            <TextDisplay
                value={company.serviceAddress || 'N/A'}
                label='Service Address' />
            {!preApproval && <TextDisplay
                value={company.billingAddress || 'N/A'}
                label='Billing Address' />}
        </dl>
    );
};
export default CompanyDisplay;
