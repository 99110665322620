import React from 'react';
import { formatPhoneNumber } from '../../helper';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';
import TextDisplay from '../common/TextDisplay';

const ContactDisplay = ({ contact, contactType }) => {
    const { preApproval } = useReviewQuoteContext();
    return (
        <dl>
            <div className={preApproval ? 'row' : ''} >
                <div className={preApproval ? 'col-6' : ''}>
                    <TextDisplay
                        value={contact.name || 'N/A'}
                        label={`${contactType} Contact`} />
                    <TextDisplay
                        value={formatPhoneNumber(contact.phone) || 'N/A'}
                        label='Phone' />
                </div>
                <div className={preApproval ? 'col-6' : ''}>
                    <TextDisplay
                        value={formatPhoneNumber(contact.mobilePhone) || 'N/A'}
                        label='Mobile Phone' />
                    <TextDisplay
                        value={contact.email || 'N/A'}
                        label='Email' />
                </div>
            </div>
        </dl>
    );
};
export default ContactDisplay;
