import React from 'react';
import Signature from './Signature';

const ElevatorAndFireAlarm = () => {
    return (
        <>
            <p>You acknowledge that Jivetel does not support the use of any Voice over IP product or service as a connection for (i) emergency medical alert systems,
                (ii) all high security monitoring systems (UL 681 or similar) or (iii) fire alarm systems (UL 864 or similar) or elevator lines.</p>
            <p>You acknowledge that it is your sole responsibility to contact your central station monitoring provider to test and verify that your fire alarm
                or security system is in good working order and in compliance with any applicable State, Federal or local law or regulation and that
                you are responsible for any additional work required to ensure the proper operating of your fire alarm, security system or elevator lines.</p>
            <p>You are also responsible for any additional work or equipment necessary to satisfy any requirements due to Federal, State or local law or
                regulation.</p>
            <p>By signing below, I am certifying that I have authority to bind the Company listed below, and that I do so bind the Company;
                that I have read the order form, the referenced Terms of Service and any other attachments (collectively, the “Order”), and that I agree
                to bind the Company to the terms of the Order.</p>
            <Signature name='elavator' />
        </>
    );
};
export default ElevatorAndFireAlarm;
