import React from 'react';
import Col from 'react-bootstrap/Col';

const RespOrgCell = ({ value }) => {
    return (
        <>
            <Col lg={5} md={6} className='border p-2'>
                <dt>{value}:</dt>
            </Col>
            <Col lg={7} md={6} className='border p-2'>
                <dt></dt>
            </Col>
        </>
    );
};
export default RespOrgCell;
