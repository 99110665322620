import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faTimes, faCheck, faAngleDown, faAngleRight, faCircleCheck, faDownload, faLongArrowAltUp, faLongArrowAltDown, faAt, faSquare, faSquareCheck, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import 'font-awesome/css/font-awesome.min.css';

library.add(fab, faTimes, faCheck, faAngleDown, faAngleRight, faCircleCheck, faDownload, faLongArrowAltUp, faLongArrowAltDown, faAt, faSquare, faSquareCheck, faTimesCircle);

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');

ReactDOM.render(
    <React.StrictMode>
        <BrowserRouter basename={baseUrl}>
            <App />
        </BrowserRouter>
    </React.StrictMode>,
    rootElement);

registerServiceWorker();
