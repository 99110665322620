import React, { useEffect, useState } from 'react';
import Collapser from '../common/Collapser';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';

const AgreementContainer = ({ children, header }) => {
    const [fullView, setFullView] = useState(true);
    const { generatingPDF } = useReviewQuoteContext();

    return (
        <div className='bg-white avoid-page-break'>
            <div className="py-5 px-2">
                <div className='d-flex justify-content-between'>
                    <div className="text-danger text-center h1 mx-auto">{header}</div>
                    {!generatingPDF && <div><Collapser fullView={fullView} onChange={setFullView} /></div>}
                </div>
                {fullView && <>{children}</>}
            </div>
        </div>
    );
};
export default AgreementContainer;
