import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { useSearchParams, useLocation } from 'react-router-dom';
import axios from 'axios';
const ReviewQuoteContext = createContext();

const ReviewQuoteContextComponent = ({ children, prefix }) => {
    const [quote, setQuote] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [contractSigned, setContractSigned] = useState(false);
    const [reviewingContract, setReviewingContract] = useState(false);
    const [countries, setCountries] = useState([]);
    const [preApproval, setPreApproval] = useState(true);
    const [signedQuote, setSignedQuote] = useState({ signatureBase64: '', dateSaved: null });
    const [contact, setContact] = useState({ firstName: '', lastName: '', email: '', phone: '', contactType: 'Billing' });
    const [activeStep, setActiveStep] = useState(0);
    const [agreements, setAgreements] = useState({
        tos: { title: 'TOS', signed: false, ref: useRef(null) },
        elavator: { title: 'Elevator and Fire Alarm', signed: false, ref: useRef(null) },
        acknowledgement: { title: 'E911 Customer Acknowledgement', signed: false, ref: useRef(null) },
        authorization: { title: 'Letter of Authorization', signed: false, ref: useRef(null) },
        tfAuthorization: { title: 'Letter of Authorization (LOA)  and Resp-Org Change Request', signed: false, ref: useRef(null) }
    });
    const [targetSignatureSlot, setTargetSignatureSlot] = useState(-1);
    const { pathname } = useLocation();
    const lowerPathname = pathname.toLowerCase();
    const startIndex = lowerPathname.indexOf(prefix) + prefix.length;
    const endIndex = lowerPathname.indexOf('/', startIndex);
    const urlQuoteID = lowerPathname.substring(startIndex, endIndex);
    const parts = pathname.split('/');
    const urlEmailHash = parts.slice(5).join('/');

    useEffect(() => {
        const controller = new AbortController();
        getCountries();
        getQuote(controller);
        getSignature(controller);
        return () => controller.abort();
    }, []);

    const getCountries = async () => {
        const { data } = await axios.post('/Api/Quotes/PostToCore?route=/API/Core/GetListOfCountries');
        setCountries(data.listOfCountries);
    };

    const getQuote = async (controller) => {
        const requestConfig = controller ? { signal: controller.signal } : null;
        const { data } = await axios.get(`/Api/Quotes/GetCore?route=/API/Quotes/GetQuote?quoteID=${urlQuoteID}`, requestConfig);
        if (data.quote) {
            setQuote(data.quote);
        }
    };

    const getSignature = async (controller) => {
        const requestConfig = controller ? { signal: controller.signal } : null;
        const contactID = urlEmailHash ? await getContactFromEmail() : null;
        const { data } = await axios.get(`/Api/Quotes/GetCore?route=/API/Quotes/GetQuoteSignature?quoteID=${urlQuoteID}&contactID=${contactID}`, requestConfig);
        if (data.signature?.signed) {
            await getContact(data.signature.contactID, requestConfig);
            setContractSigned(true);
        }
        setSignedQuote(data.signature);
        setPreApproval(!data.signature?.signed);
        setReviewingContract(data.signature?.signed);
    };

    const getContactFromEmail = async () => {
        const email = atob(urlEmailHash);
        const { data } = await axios.get(`/Api/Quotes/GetCore?route=/API/Quotes/GetContactFromEmail?quoteID=${urlQuoteID}&email=${email}`);
        setContact({
            ...data.contact,
            email,
            phone: data.contact.listOfCommunications.find(c => c.communicationType === 'PhoneNumber' || c.communicationType === 'MobileNumber')?.value
        });
        return data.contact.contactID;
    };

    const getContact = async (contactID, requestConfig) => {
        const { data } = await axios.get(`/Api/Quotes/GetCore?route=/API/Contacts/GetContact?contactID=${contactID}`, requestConfig);
        const communicatinos = data.contact.listOfCommunications;
        setContact({
            ...data.contact,
            email: communicatinos.find(c => c.communicationType === 'EmailAddress')?.value,
            phone: communicatinos.find(c => c.communicationType === 'PhoneNumber' || c.communicationType === 'MobileNumber')?.value
        });
    };

    const generatingPDF = searchParams.get('generatingPDF') ? searchParams.get('generatingPDF').toLowerCase() === 'true' : false;

    const value = {
        quote,
        contractSigned,
        setContractSigned,
        preApproval,
        setPreApproval,
        generatingPDF,
        reviewingContract,
        setReviewingContract,
        showAgreements: searchParams.get('showAgreements') || false,
        countries,
        signedQuote,
        setSignedQuote,
        contact,
        setContact,
        urlEmailHash,
        activeStep,
        setActiveStep,
        agreements,
        setAgreements,
        targetSignatureSlot,
        setTargetSignatureSlot
    };

    return (
        <ReviewQuoteContext.Provider value={value}>
            {children}
        </ReviewQuoteContext.Provider>
    );
};

const useReviewQuoteContext = () => {
    return useContext(ReviewQuoteContext);
};

export { ReviewQuoteContextComponent, useReviewQuoteContext };
