import React from 'react';
import Form from 'react-bootstrap/Form';

const InvalidFeedback = ({ message }) => {
  return (
        <Form.Control.Feedback type="invalid">
            {message ?? 'This is a required field'}
        </Form.Control.Feedback>
  );
};
export default InvalidFeedback;
