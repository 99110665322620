import React from 'react';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';
import CheckBox from '../common/CheckBox';

const ContactCheckBoxes = ({ contact, onContactCheck, baseTabIndex }) => {
    const { reviewingContract } = useReviewQuoteContext();
    return (
        <div className='row'>
            <div className='col-lg-6 col-md-6 col-xs-12'>
                <CheckBox checked={contact.isAuthorized || false}
                    tabIndex={baseTabIndex + 1}
                    label='Authorized User'
                    id={`isAuthorized-${baseTabIndex}`}
                    name='isAuthorized'
                    disabled={!!reviewingContract}
                    onChange={onContactCheck} />
            </div>
            <div className='col-lg-6 col-md-6 col-xs-12'>
                <CheckBox checked={contact.isSendStatement || false}
                    tabIndex={baseTabIndex + 2}
                    id={`isSendStatement-${baseTabIndex}`}
                    name='isSendStatement'
                    label='Send Statement'
                    disabled={!!reviewingContract}
                    onChange={onContactCheck} />
            </div>
        </div>
    );
};
export default ContactCheckBoxes;
