import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    DataGrid,
    Column,
    Paging,
    Summary,
    TotalItem,
    Sorting
} from 'devextreme-react/data-grid';
import './/css/charges.css';
import { FormatCurrency } from '../common/FormatCurrency';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';
import { useParams } from 'react-router-dom';

const Charges = ({ isRecurring, title, onTotalUpdate }) => {
    const [charges, setCharges] = useState([]);
    const [showDiscount, setShowDiscount] = useState(false);
    const { quote: { allowCustomerToEdit }, reviewingContract } = useReviewQuoteContext();
    const urlQuoteID = useParams().quoteID;

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        getQuoteCharges(signal);
        return () => {
            controller.abort();
        };
    }, [urlQuoteID]);

    useEffect(() => {
        const filteredCharges = charges.filter(c => !c.onPaymentPlan).reduce((prev, current) => prev + current.subTotal, 0);
        const sum = charges.reduce((prev, current) => prev + current.subTotal, 0);
        const tax = charges.reduce((prev, current) => prev + current.salesTax, 0);
        onTotalUpdate({ subTotal: sum, tax, filteredCharges });
    }, [charges]);

    const getQuoteCharges = async (signal) => {
        const { data } = await axios.post('/Api/Quotes/PostToCore?route=/API/Quotes/GetQuoteCharges', { quoteID: urlQuoteID, recurring: isRecurring, signal, userFacing: true });
        if (!signal.aborted) {
            const discount = data.charges.reduce((prev, current) => prev + current.discountAmount, 0);
            setShowDiscount(discount > 0);
            const chargesToShow = data.charges.filter(c => !(c.unitPrice === 0 && c.primaryChargeID));
            setCharges(chargesToShow);
        }
    };

    const format = {
        type: 'currency',
        precision: 2
    };

    const formatPrice = {
        type: 'currency',
        precision: 3
    };

    const discountDisplay = (cellInfo) => {
        if (cellInfo.data.discountAmount > 0) {
            if (cellInfo.data.discountType === 'Percent') {
                return `${cellInfo.data.discountPercent}%`;
            }
            return FormatCurrency(cellInfo.data.discountAmount);
        }
        return '';
    };

    const description = (cellInfo) => {
        if (cellInfo.data.onPaymentPlan) {
            return <div>{cellInfo.data.description} <span className="badge badge-pill badge-primary">On Payment Plan</span></div>;
        }
        return cellInfo.data.description;
    };
    if (urlQuoteID <= 0) {
        return (<>
            <div>Loading....</div>
        </>);
    }

    const customizeTotalText = (e) => {
        return 'Totals:';
    };

    const renderCells = (cellInfo) => {
        if (cellInfo.data.equipmentID === 'COMMENT_MRC' || cellInfo.data.equipmentID === 'COMMENT_NRC') {
            return <div></div>;
        }
        return cellInfo.column.dataField === 'quantity' ? cellInfo.value.toString() : FormatCurrency(cellInfo.value);
    };

    return (<>
        <div>
            <h5> <b>{title}</b></h5>
            <DataGrid
                showBorders={true}
                rowAlternationEnabled={true}
                dataSource={charges}
                keyExpr="quoteChargeID"
                repaintChangesOnly={true}
                showRowLines={false}
                columnHidingEnabled={false}
            >
                <Sorting mode="none" /> {/* or "multiple" | "none" */}
                <Paging enabled={false} />
                <Column dataField="rowIndex" visible={false} sortOrder="asc"></Column>
                <Column dataField="chargeID" visible={false}></Column>
                <Column dataField="equipmentID" visible={false} caption="Equipment"> </Column>
                <Column dataField="description" width="35%" cellRender={description}></Column>
                <Column dataField="unitPrice" caption="Price" dataType="number" cellRender={renderCells}></Column>
                <Column dataField="quantity" caption='QTY' cellRender={renderCells}></Column>
                <Column dataField="discount" dataType="number" cellRender={discountDisplay} visible={showDiscount}></Column>
                <Column dataField="subTotal" caption="Subtotal" cellRender={renderCells}></Column>
                <Column dataField="salesTax" caption="Tax" cellRender={renderCells}></Column>
                <Column dataField="total" cellRender={renderCells}></Column>
                <Summary>
                    <TotalItem
                        showInColumn="description"
                        customizeText={customizeTotalText}
                    >
                    </TotalItem>
                    <TotalItem
                        column="subTotal"
                        summaryType="sum"
                        valueFormat={format}
                        displayFormat="{0}"
                        showInColumn="subTotal"
                        cssClass="totals-summary"
                    >
                    </TotalItem>
                    <TotalItem
                        column="salesTax"
                        summaryType="sum"
                        valueFormat={format}
                        displayFormat="{0}"
                        showInColumn="salesTax"
                        cssClass="totals-summary"
                    >
                    </TotalItem>
                    <TotalItem
                        column="total"
                        summaryType="sum"
                        valueFormat={format}
                        displayFormat="{0}"
                        name="sumOfCharges"
                        showInColumn="total"
                        cssClass="totals-summary"
                    >
                    </TotalItem>
                </Summary>
            </DataGrid>
        </div>
    </>);
};

export default Charges;
