import React from 'react';
import Signature from './Signature';

const TOSAgreement = () => {
  return (<>
        <p>If you are entering into this Service Order on behalf of a company or other legal entity,
            you represent that you have the authority to bind such company or other legal entity to this Service Order.</p>
        <p> JIVETEL SERVICE AGREEMENT IS SUBJECT TO THE TERMS OF SERVICE AVAILABLE AT <a href="https://www.jivetel.com/terms-of-service"> https://www.jivetel.com/terms-of-service</a>,
            (“TERMS OF SERVICE”) A COPY OF WHICH WILL BE PROVIDED TO CUSTOMER UPON REQUEST. SUCH TERMS OF SERVICE ARE
            INCORPORATED HEREIN BY THIS REFERENCE. BY EXECUTING THIS JIVETEL SERVICE AGREEMENT WHERE INDICATED BELOW,
            CUSTOMER ACKNOWLEDGES THAT</p>
        <p>(1) CUSTOMER ACCEPTS AND AGREES TO BE BOUND BY THE TERMS OF SERVICE, INCLUDING THE
            ARBITRATION SECTION THEREIN, WHICH PROVIDES THAT THE PARTIES DESIRE TO RESOLVE DISPUTES RELATING TO THE JIVETEL
            SERVICE AGREEMENT THROUGH ARBITRATION;</p>
        <p>AND (2) BY AGREEING TO ARBITRATION, CUSTOMER IS GIVING UP VARIOUS RIGHTS,
            INCLUDING THE RIGHT TO TRIAL BY JURY. CUSTOMER FURTHER AGREES TO THE INDEMNIFICATION TERMS SET FORTH IN THE TERMS
            OF SERVICE.</p>
        <p>Customer further agrees to be bound by the Term Commitment of this agreement, and will pay an
            early termination fee if Customer cancels services before the end of the Term Commitment, as set forth in the Terms of Service. Customer further agrees that it
            has provided or secured on behalf of Jivetel all third-party rights necessary for installation of service.
            Customer will pay the Rates set forth above, including any Monthly Recurring Charges and Non-recurring Charges as set forth above, in accordance with the Jivetel Terms
          of Service.</p>
      <Signature name='tos' />
    </>
  );
};
export default TOSAgreement;
