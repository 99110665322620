import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import coverLetterImage from '../images/home-scalibility.jpg';
import { capitalizeFirstLetter } from '../helper';
import logo from '../images/logo-hi-res.png';

const PdfCoverLetter = () => {
    require('../css/cover-letter.css'); // using require instead of import since I want this css to be scoped to only this componenet - not other non scoped componenets //
    const [quote, setQuote] = useState({});
    const [companyInfo, setCompanyInfo] = useState({});
    const [contact, setContact] = useState('');
    const [rep, setRep] = useState({});
    const urlQuoteID = useParams().quoteID;

    useEffect(() => {
        getQuote();
        getCompanyInfo();
    }, [urlQuoteID]);

    useEffect(() => {
        getContact();
        getJivetelRepEmail();
    }, [quote]);

    const quoteSigned = quote.statusID > 2;

    const getQuote = async () => {
        const { data } = await axios.get(`/Api/Quotes/GetCore?route=/API/Quotes/GetQuote?quoteID=${urlQuoteID}`);
        if (data.quote) {
            setQuote(data.quote);
        }
    };

    const getCompanyInfo = async () => {
        const { data } = await axios.get(`/Api/Quotes/GetCore?route=/API/Quotes/GetCompanyInfo?quoteID=${urlQuoteID}`);
        const { name, listOfLocations } = data.companyInfo;
        const serviceAddress = listOfLocations.find(l => l.companyLocation.addressType === 'Service');
        setCompanyInfo({ name, address: serviceAddress });
    };

    const getContact = async () => {
        const url = `/Api/Quotes/GetCore?route=/API/Quotes/${quoteSigned ? 'GetPrimaryContact' : 'GetLeadContact'}?quoteID=${urlQuoteID}`;
        const { data } = await axios.get(url);
        const contactData = quoteSigned ? data.primaryContact : data.leadContact.contact;
        const fullName = contactData.firstName?.concat(' ', contactData.lastName);
        setContact(fullName);
    };

    const getJivetelRepEmail = async () => {
        const userID = quote.salesRepUserID > 0 ? quote.salesRepUserID : quote.userID;
        const { data } = await axios.post(`/Api/Quotes/PostToCore?route=/API/Quotes/GetUsersEmail?userID=${userID}`);
        setRep({ email: data.email, fullName: data.fullName });
    };

    // const quoteTypes = (val) => {
    //    switch (val) {
    //        case 'NS':
    //            return 'Hosted PBX - NS';
    //        case 'SIPTrunk':
    //            return 'PRI / SIPTrunking';
    //        case '_3CX':
    //            return 'Premised PBX - 3CX';
    //        default: return '';
    //    }
    // };

    return (<>
        <div className='bg-gray-200 p-0 d-flex flex-column justify-content-between' id='cover-letter'>
            <div className='d-flex justify-content-center align-items-center flex-row pt-2'>
                <div className='col-10 py-3'>
                    <div className='row justify-content-center py-5'>
                        <a href='https://www.jivetel.com/' target='_blank' rel="noreferrer">
                            <img style={{ height: '125px' }} src={logo} />
                        </a>
                    </div>
                    <div>
                        <p className='title-text pt-5'>{capitalizeFirstLetter(quote.quoteName)}</p>
                        {quote?.displayDescription && <p className='large-text-gray'>{capitalizeFirstLetter(quote?.description)}</p>}
                    </div>
                    <div className='row p-3 '>
                        <div className='col-7 pt-2'>
                            <div className='row gray-text mb-3'><span>Prepared For</span></div>
                            <div className='row large-text mb-2'><span>{capitalizeFirstLetter(companyInfo?.name)}</span></div>
                            <div className='row value'><span>{capitalizeFirstLetter(contact)}</span></div>
                            {companyInfo.address && <div> <div className='row value'><span>{companyInfo?.address?.address}</span></div>
                                <div className='row value'><span>{companyInfo?.address?.city}, {companyInfo?.address?.state} {companyInfo?.address?.zipCode}</span></div></div>}
                        </div>
                        <div className='pt-2 col-5'>
                            <div className='row align-items-center'>
                                <div className='col-6'>
                                    <div className='row gray-text mb-3'><span>Prepared By</span></div>
                                    <div className='row large-text mb-4'><span>{rep.fullName}</span></div>
                                    <div className='row value mt-1'><span><a href="mailto:{repEmail}">{rep.email}</a></span></div>
                                    <div className='row value'><span><a href="tel:845-364-7000">845-364-7000</a></span></div>
                                </div>
                                {/* <div className='col-6'> */}
                                {/*    <a href='https://www.jivetel.com/' target='_blank' rel="noreferrer"> */}
                                {/*        <img className='img-fluid w-75' src={logo} /> */}
                                {/*    </a> */}
                                {/* </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='d-flex align-items-bottom'><img className='cover-letter-img' src={coverLetterImage} /> </div>
        </div>
    </>);
};
export default PdfCoverLetter;
