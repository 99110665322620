import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import '../components/header/css/assets.css';
import TollFreeAuthorization from '../components/legal/TollFreeAuthorization';
import logo from '../images/logo-hi-res.png';

const TollFreeAgreement = () => {
    require('../css/custom.css');
    const [searchParams, setSearchParams] = useSearchParams();
    const singlePage = searchParams.get('singlePage') === 'True';
    var title = 'Letter of Authorization (LOA)  and Resp-Org Change Request';
    useEffect(() => {
        document.title = 'Toll Free Auth';
    }, []);

    return (<>
        <div className={singlePage ? 'container h5' : ''}>
            {singlePage && <div className='d-flex justify-content-center pt-5'>
                <div>
                    <a href='https://www.jivetel.com/' target='_blank' rel="noreferrer">
                        <img style={{ height: '150px' }} src={logo} />
                    </a>
                    <p className="jive-blue mt-2 ml-3">IP TELEPHONY MADE SIMPLE</p>
                </div>
            </div>}
            {singlePage
                ? <h3 className='text-center font-weight-bold'>{title}</h3>
                : <div className='d-flex justify-content-between'>
                    <div className='text-danger text-center h1 mx-auto'>{title}</div>
                </div>}
            <TollFreeAuthorization singlePage={singlePage} />
        </div>
    </>);
};
export default TollFreeAgreement;
