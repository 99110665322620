import React from 'react';

const CheckBox = ({ name, label, onChange, checked, id, wrapperClass, labelClass, required = false, disabled, tabIndex, customAttributes }) => {
    return (<>
        <div className={`${disabled ? '' : 'cursor-pointer'} ${wrapperClass}`} >
            <input type='checkbox'
                className={`${disabled ? '' : 'cursor-pointer custom-checkbox-active'} custom-checkbox`}
                tabIndex={tabIndex}
                id={id}
                required={required}
                disabled={disabled}
                checked={checked}
                name={name}
                onChange={onChange}
                {...customAttributes} />
            <label htmlFor={id} className={`pl-2 form-label${disabled ? '' : ' cursor-pointer'} ${labelClass}`}>{label}</label>
        </div>
    </>
    );
};

export default CheckBox;
