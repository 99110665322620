import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import LinkExpired from '../components/quotes/LinkExpired';
import UnsubscribeCard from '../components/unsubscribeEmail/UnsubscribeCard';
import Header from '../components/header/Header';
import Alert from '../components/common/Alert';
import EmptyList from '../components/unsubscribeEmail/EmptyList';

const Unsubscribe = () => {
    const hash = useParams().hash;
    const [isValidated, setIsValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [emailAddress, setEmailAddress] = useState('');
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [emptyList, setEmptyList] = useState(false);

    const category = (action) => {
        switch (action) {
            case 'Additional Contact':
                return '';
            case 'Extensions':
            case 'FS User':
                return 'Voicemail';
            case 'Email Gateway':
                return 'SMS';
            default:
                return action;
        }
    };

    useEffect(() => {
        axios.get(`/Api/Quotes/GetCore?route=/API/Core/GetEmailUnsubscribeHashInfo?hash=${hash}`).then(data => {
            console.log(data);
            if (data.data.emailAddress != null) {
                setIsValidated(true);
                setEmailAddress(data.data.emailAddress);
            } else {
                setIsLoading(false);
            }
        });
    }, [isValidated]);

    useEffect(() => {
        setFormSubmitted(false);
        if (emailAddress !== '') {
            axios.get(`/Api/Quotes/GetCore?route=/API/Core/MassManageEmailsActions?email=${emailAddress}`).then(data => {
                console.log(data.data);
                setSubscriptionData(data.data.table.map(d => {
                    if (d.action !== 'Tickets') {
                        return { ...d, subscribed: true, category: category(d.action) };
                    } else {
                        return { ...d, category: category(d.action) };
                    }
                }));
                setIsLoading(false);
                setEmptyList(!data.data.table.length);
            });
        }
    }, [emailAddress, formSubmitted]);

    const updateData = data => {
        setSubscriptionData(data);
    };

    const submitForm = (customerName) => {
        const postData = subscriptionData.filter(d => d.customerName === customerName || d.action === 'Tickets');
        axios.post('/Api/Quotes/PostToCore?route=/API/Core/MassDeleteEmailActions', postData).then(result => {
            console.log(result);
            Alert(`You have succesfully updated you Jivetel email preferences ${customerName !== '' ? `for ${customerName}` : ''}. If you feel this was a error please reach out to customer service.`, 'success');
            setFormSubmitted(true);
        });
    };

    return (
        <>
            {isValidated && !isLoading && !emptyList && <>
                <Header />
                <UnsubscribeCard
                    data={subscriptionData}
                    emailAddress={emailAddress}
                    submitForm={submitForm}
                    updateData={updateData}
            /></>}
            {!isValidated && !isLoading && <LinkExpired />}
            {isValidated && !isLoading && emptyList && <EmptyList />}
        </>
    );
};

export default Unsubscribe;
