import React from 'react';
import InvalidFeedback from './InvalidFeedback';

function DropDownBox ({ options, placeholder, defaultValue, onChange, name = '', className = '', disabled = false, required = false, tabIndex }) {
    const wrapperClass = 'form-group';

    if (options !== undefined) {
        return (
            <div className={wrapperClass}>
                <select required={required}
                    tabIndex={tabIndex}
                    className={`form-control select ${className}`}
                    disabled={disabled}
                    name={name} value={defaultValue}
                    onChange={(e) => onChange(e)}>
                    <option key="0" value="">
                        {placeholder}
                    </option>
                    {options.map((l) => {
                        return <option key={l.value} value={l.value}>{l.text}</option>;
                    })}
                </select>
                <InvalidFeedback />
            </div>
        );
    } else {
        return '';
    }
}
export default DropDownBox;
