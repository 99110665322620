import React from 'react';
import TextDisplay from '../common/TextDisplay';
import TextInput from '../common/TextInput';

const ContactInputs = ({ contact, onTextChange, baseTabIndex }) => {
    return (
        <div className='row'>
            <div className='col-md-6 col-12'>
                <TextInput
                    tabIndex={baseTabIndex + 1}
                    required
                    value={contact.name}
                    name='name'
                    placeholder='Name'
                    pattern='([^\s]+\s)+[^\s]+'
                    onChange={onTextChange} />
                <TextInput
                    tabIndex={baseTabIndex + 3}
                    required={!contact.mobilePhone}
                    value={contact.phone}
                    name='phone'
                    placeholder='Phone'
                    onChange={onTextChange} />
            </div>
            <div className='col-md-6 col-12'>
                <TextInput
                    tabIndex={baseTabIndex + 2}
                    value={contact.mobilePhone}
                    name='mobilePhone'
                    placeholder='Mobile Phone'
                    onChange={onTextChange} />
                <TextInput
                    tabIndex={baseTabIndex + 4}
                    required
                    value={contact.email}
                    name='email'
                    type='email'
                    placeholder='Email'
                    onChange={onTextChange} />
            </div>
        </div>
    );
};
export default ContactInputs;
