import React, { useRef, useState, useEffect } from 'react';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';
import Form from 'react-bootstrap/Form';
import CompanyInfoDisplay from './CompanyInfoDisplay';
import LocationsDisplay from './LocationsDisplay';
import CheckBox from '../common/CheckBox';
import { getEmptyContact, getEmptyLocation, objectMatches } from '../../helper';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import AddressValidationModal from './AddressValidationModal';

const CompanyInfoWrapper = ({ onFormSubmitted, paymentFormRef, paymentButtonRef, paymentRequired, onServiceAddressChanged, submitButtonRef, onPopupChange }) => {
    const [companyInfo, setCompanyInfo] = useState({ companyName: '', companyAddress: '', extensionsCount: 0 });
    const [primaryContact, setPrimaryContact] = useState(getEmptyContact());
    const [billingContact, setBillingContact] = useState({ ...getEmptyContact(), quoteContactTags: ['Billing'] });
    const [serviceLocation, setServiceLocation] = useState({ ...getEmptyLocation(), addressType: 'Service' });
    const [origServiceLocation, setOrigServiceLocation] = useState({ ...getEmptyLocation(), addressType: 'Service' });
    const [billingLocation, setBillingLocation] = useState({ ...getEmptyLocation(), addressType: 'Billing' });
    const [origBillingLocation, setOrigBillingLocation] = useState({ ...getEmptyLocation(), addressType: 'Billing' });
    const [infoConfirmed, setInfoConfirmed] = useState(false);
    const [hasValidationRun, setHasValidationRun] = useState(false);
    const [showValidateAddressModal, setShowValidateAddressModal] = useState(false);
    const [submitAndSign, setSubmitAndSign] = useState(false);
    const dataRef = useRef(null);
    const addressRef = useRef(null);
    const { quote, reviewingContract, generatingPDF, preApproval, contact, setActiveStep } = useReviewQuoteContext();
    const navigate = useNavigate();
    const urlHash = useParams().hash;
    const urlQuoteID = useParams().quoteID;

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            getServiceAddress().then(serviceAddress => {
                onServiceAddressChanged(serviceAddress.zipCode !== serviceLocation.zipCode);
                if (serviceLocation.zipCode && serviceAddress.zipCode !== serviceLocation.zipCode) {
                    recalculateTaxes();
                }
            });
        }, 2000);
        return () => clearTimeout(timeOutId);
    }, [serviceLocation]);

    useEffect(() => {
        submitButtonRef.current = {
            click: (e, sign) => onFormSubmit(e, sign)
        };
    }, [submitButtonRef, companyInfo, primaryContact, billingContact, serviceLocation, billingLocation, infoConfirmed, quote.quoteID]);

    const getServiceAddress = () => {
        return axios.get(`/Api/Quotes/GetCore?route=/API/Quotes/GetQuoteLocations?quoteID=${quote.quoteID}&companyID=${quote.companyID}&addressType=Service`).then(result => {
            return result.data.locations[0] || {};
        });
    };
    const onFormSubmit = async (e, sign, formResult) => {
        var sendStatementMissing = !primaryContact.isSendStatement && !billingContact.isSendStatement;
        if ((!dataRef.current.checkValidity() || !infoConfirmed || sendStatementMissing) && sign) {
            setHasValidationRun(true);
            let targetRef = addressRef;
            if (!dataRef.current.checkValidity()) {
                targetRef = dataRef;
            }
            targetRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            setPopupProperties();
            return;
        }
        const result = { sign };
        if (paymentRequired) {
            await paymentButtonRef.current.click(paymentFormRef, result, billingContact.email, serviceLocation.zipCode);
        }
        if (paymentRequired && (!paymentFormRef.current.checkValidity() || !result.validData) && !result.ignore) {
            paymentFormRef.current.scrollIntoView({ behavior: 'smooth' });
            return;
        }
        setSubmitAndSign(sign);
        if (getAddressesToValidate().length) {
            setShowValidateAddressModal(true);
        } else {
            saveAndReset(null, sign);
        }
    };

    const setPopupProperties = () => {
        const errorMessages = [];
        if (!companyInfo.companyName) {
            errorMessages.push('Company Name');
        }
        if (!isContactComplete(primaryContact)) {
            errorMessages.push('Primary Contact');
        }
        if (!isContactComplete(billingContact)) {
            errorMessages.push('Billing Contact');
        }
        if (!primaryContact.isSendStatement && !billingContact.isSendStatement) {
            errorMessages.push('At least one contact should be set to receive statements');
        }
        if (!isLocationComplete(serviceLocation)) {
            errorMessages.push('Service Location');
        }
        if (!isLocationComplete(billingLocation)) {
            errorMessages.push('Billing Location');
        }
        if (!infoConfirmed) {
            errorMessages.push('Confirmation Checkbox');
        }
        setTimeout(() => {
            onPopupChange({
                show: true,
                errorMessages,
                title: 'Missing Info',
                helperText: `The following ${errorMessages.length > 1 ? 'pieces of data are' : 'piece of data is'} required before you continue to sign.`
            });
        }, 300); // showing popup at the same time as scrolling will cause scrolling issues
    };

    const isContactComplete = (contact) => {
        return contact.name && /([^\s]+\s)+[^\s]+/g.test(contact.name) && (contact.mobilePhone || contact.phone) && contact.email;
    };

    const isLocationComplete = (location) => {
        return getTargetProperties(location, true).every(k => location[k]);
    };

    const saveData = async (addresses) => {
        await saveCompany();
        await saveContacts();
        await saveLocations(addresses);
    };

    const saveCompany = async () => {
        if (!companyInfo.companyName) {
            return;
        }
        const updatedCompany = { ...quote.company, name: companyInfo.companyName, externalContactID: contact.contactID, quoteID: urlQuoteID };
        const { data } = await axios.post('/Api/Quotes/PostToCore?route=/API/Quotes/SaveCompany', updatedCompany);
    };

    const saveContacts = async () => {
        const contacts = [];
        const primaryData = getContact(primaryContact, true);
        if (primaryData) {
            contacts.push(primaryData);
        }
        const billingData = getContact(billingContact, false);
        if (billingData) {
            contacts.push(billingData);
        }
        if (!contacts.length) {
            return;
        }
        const { data } = await axios.post('/Api/Quotes/PostToCore?route=/API/Quotes/AssignManyContacts', { contacts, externalContactID: contact.contactID || null });
        console.log(data);
    };

    const saveLocations = async (addresses) => {
        if (!addresses) {
            return;
        }
        if (!addresses.find(a => a.addressType === 2) && isAddressFilled(serviceLocation)) {
            addresses.push(serviceLocation);
        }
        if (!addresses.find(a => a.addressType === 0) && isAddressFilled(billingLocation)) {
            addresses.push(billingLocation);
        }
        addresses.map(a => {
            saveLocation({ ...a, address: a.address || a.address1 });
            return a;
        });
    };

    const isAddressFilled = (a) => {
        return getTargetProperties(a).some(k => a[k]);
    };

    const saveLocation = async (location) => {
        const { data } = await axios.post('/Api/Quotes/PostToCore?route=/API/Quotes/SaveLocation', { ...location, companyID: quote.companyID, quoteID: quote.quoteID, name: '', externalContactID: contact.contactID || null });
        return data;
    };

    const recalculateTaxes = async () => {
        const { data } = await axios.post(`/Api/Quotes/PostToCore?route=/API/Quotes/RecalculateTaxes?quoteID=${urlQuoteID}`);
    };

    const targetContactProperties = ['name', 'mobilePhone', 'phone', 'email'];

    const getContact = (contact, isPrimary) => {
        if (targetContactProperties.every(k => !contact[k])) {
            return;
        }
        const [firstName, lastName] = contact.name.trim().split(' ');
        return {
            contactID: contact.contactID,
            firstName,
            lastName,
            listOfCommunications: [
                { commID: contact.emailID ?? 0, value: contact.email, communicationType: 'EmailAddress' },
                { commID: contact.phoneID ?? 0, value: contact.phone, communicationType: 'PhoneNumber' },
                { commID: contact.mobilePhoneID ?? 0, value: contact.mobilePhone, communicationType: 'MobileNumber' }
            ],
            quoteID: quote.quoteID,
            companyID: quote.companyID,
            isAuthorized: contact.isAuthorized,
            isSendStatement: contact.isSendStatement,
            isHolidayReminder: contact.isHolidayReminder,
            isPrimary,
            assignToCompany: true,
            quoteContactTags: contact.quoteContactTags
        };
    };

    const showCheckbox = !reviewingContract && !generatingPDF && !preApproval;

    const saveAndReset = async (addresses, sign) => {
        onFormSubmitted.before();
        await saveData(addresses);
        onFormSubmitted.after(sign || submitAndSign);
        setActiveStep(2);
        setHasValidationRun(false);
    };

    const getTargetProperties = (a, filterAddress2) => {
        const filteredProperties = ['name', 'locationID'];
        if (filterAddress2) {
            filteredProperties.push('address2');
        }
        const condition = (k) => !filteredProperties.includes(k);
        return Object.keys(a).filter(condition);
    };

    const getAddressesToValidate = () => {
        return [serviceLocation, billingLocation].filter(a => {
            return getTargetProperties(a, true).every(k => a[k]) && !objectMatches(a, getOrigLocation(a.addressType));
        }).map(a => {
            a.address1 = a.address;
            return a;
        });
    };

    const getOrigLocation = (addressType) => {
        if (addressType === 'Service') {
            return origServiceLocation;
        } else if (addressType === 'Billing') {
            return origBillingLocation;
        }
    };

    const onServiceLocationChange = (location, setOriginalState) => {
        setServiceLocation(location);
        if (setOriginalState) {
            setOrigServiceLocation(location);
        }
    };

    const onBillingLocationChange = (location, setOriginalState) => {
        setBillingLocation(location);
        if (setOriginalState) {
            setOrigBillingLocation(location);
        }
    };

    return (
        <>
            <Form noValidate validated={hasValidationRun} id='data-form' onSubmit={onFormSubmit} ref={dataRef}>
                <CompanyInfoDisplay companyInfo={companyInfo}
                    onCompanyInfoChange={setCompanyInfo}
                    primaryContact={primaryContact}
                    onPrimaryContactChange={setPrimaryContact}
                    billingContact={billingContact}
                    onBillingContactChange={setBillingContact}
                    className="avoid-page-break" />
                <div ref={addressRef} className='mt-5'>
                    <LocationsDisplay
                        serviceLocation={serviceLocation}
                        onServiceLocationChange={onServiceLocationChange}
                        billingLocation={billingLocation}
                        onBillingLocationChange={onBillingLocationChange} />
                </div>
                {showCheckbox && <>
                    <div className='mt-4'>
                        <CheckBox checked={infoConfirmed}
                            tabIndex={26}
                            id='info-confirmed'
                            required
                            onChange={(e) => setInfoConfirmed(e.target.checked)}
                            label='I acknowledge that the above information has been reviewed and confirmed as accurate' />
                        {(hasValidationRun && !infoConfirmed) && <small className='text-danger'>This checkbox is required</small>}
                    </div></>}
                <button type='submit' hidden ref={submitButtonRef} />
            </Form>
            <AddressValidationModal myAddresses={getAddressesToValidate()}
                show={showValidateAddressModal}
                onClose={() => setShowValidateAddressModal(false)}
                onContinueCallBack={saveAndReset}
            />
        </>
    );
};
export default CompanyInfoWrapper;
