import React from 'react';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';

const InlineText = ({ label, value }) => {
    const { generatingPDF } = useReviewQuoteContext();
    return (
        <div className={`${generatingPDF ? 'h3 text-muted mt-4' : 'gray-text mt-3'}`}>{label}:
            <span className={`${generatingPDF ? 'font-weight-bold h3' : 'value'} pl-2`}>{value}</span>
        </div>
    );
};
export default InlineText;
