import React from 'react';

const JiveCard = ({ children, title }) => {
    return (
        <div className="container">
            <div className="row justify-content-center align-items-center min-vh-100">
                <div className="col-lg-6 col-md-8 col-sm-10">
                    <div className="card shadow-lg p-3 mb-5 bg-white rounded">
                        <div className="card-body text-center">
                            <h1 className="card-title mb-4 jive-red">{title}</h1>
                            {children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default JiveCard;
