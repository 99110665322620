import { Popup, ToolbarItem } from 'devextreme-react/popup';
import React from 'react';
import './css/quoteApprovedPopup.css';

const QuoteApprovedPopup = ({ showQuoteApprovedPopup, onClose }) => {
    const okButtonOptions = {
        text: 'OK',
        onClick: onClose
    };

    return <Popup
        visible={showQuoteApprovedPopup}
        showCloseButton={true}
        onHiding={onClose}
        title="Quote Approved"
        minWidth={250}
        maxWidth={450}
        height={300}
        position={{ my: 'top', at: 'top', offset: '0, 100' }}
    >
        <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            location="after"
            options={okButtonOptions}
        />
        <p>Thank you for approving the quote. In order to complete the quote, please fill out all required fields and sign this form.</p>
    </Popup>;
};
export default QuoteApprovedPopup;
