import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';
import './/css/positions.css';
import { FormatCurrency } from '../common/FormatCurrency';
import { notify } from '../../helper';
import logo from '../../images/jivetel-logo.png';
import axios from 'axios';
import QuoteApprovedPopup from '../quotes/QuoteApprovedPopup';
import DropDownButton from 'devextreme-react/drop-down-button';
import QuoteProgressBar from './QuoteProgressBar';
import ArrowButton from '../common/ArrowButton';

const QuoteInfo = ({ disableButton, onFinishClick, submitButtonRef }) => {
    const { quote, reviewingContract, preApproval, setPreApproval, generatingPDF, contractSigned, urlEmailHash, agreements, targetSignatureSlot, setTargetSignatureSlot } = useReviewQuoteContext();
    const [showQuoteApprovedPopup, setShowQuoteApprovedPopup] = useState(false);

    const onApprove = async () => {
        setPreApproval(false);
        // notify('Quote approved.');
        await sendQuoteApprovedAlert();
        setShowQuoteApprovedPopup(true);
    };

    const sendQuoteApprovedAlert = async () => {
        await axios.post('/Api/Quotes/PostToCore?route=/API/Quotes/SendQuoteApprovedAlert', { quoteID: quote.quoteID, email: atob(urlEmailHash) });
    };

    const finishingStage = reviewingContract && !generatingPDF && !contractSigned && !preApproval;

    const agreementsArr = Object.keys(agreements);

    const totalSignatureCount = agreementsArr.length;

    const getAgreements = (signed) => agreementsArr.filter(k => agreements[k].signed === signed);

    const countOfSignaturesSigned = getAgreements(true).length;

    const goToNextAgreement = (index) => {
        const targetAgreement = agreements[agreementsArr[index]];
        if (targetAgreement?.ref?.current) {
            targetAgreement.ref.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
            setTargetSignatureSlot(index);
        }
    };

    const onSubmitClick = async (e) => {
        await submitButtonRef.current.click(e, e.itemData === 'To Sign');
    };

    return (<>
        <div className='col-12 p-0 position-xl-sticky-top'>
            <div className={reviewingContract ? 'card bg-darken pb-2 mt-0 rounded shadow-sm' : 'card bg-darken mb-3 pb-2 mt-0 rounded shadow-sm'}>{/* needs to be swapped to when printing is true */}
                <div className='mb-1 pb-1 border-bottom px-xl-4 px-2'>
                    <div className='row py-3  d-flex justify-content-around px-2 px-md-0'>
                        <div className="col-xl-9 col-md-8 col-7">
                            <div className="d-flex justify-content-lg-start flex-lg-row flex-column pl-lg-0 pl-4">
                                <div className='px-lg-5 border-lg-right'>
                                    <a href='https://www.jivetel.com/' target="_blank" rel="noreferrer">
                                        <img className='img-fluid w-lg-75' src={logo} />
                                    </a>
                                </div>
                                <div className='align-self-start align-self-lg-end pl-lg-5'>
                                    <h2>
                                        <b>Quote #{quote.quoteID}</b>
                                    </h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-md-3 col-5 align-self-end">
                            {(!reviewingContract && !preApproval) && <DropDownButton
                                text="Continue"
                                id='continue'
                                dropDownOptions={{ width: 160 }}
                                items={['To Sign', 'And Save For Later']}
                                onItemClick={onSubmitClick}
                            />}
                            {preApproval && <button className='btn btn-blue large-button bg-dark' onClick={onApprove}>
                                <span className='white-text'>Approve & Continue</span>
                            </button>}
                            {finishingStage && <>
                                <button className={`btn btn-green btn-block ${disableButton ? 'disabled' : ''}`}
                                    onClick={onFinishClick}
                                    disabled={disableButton}>
                                    <span className='ml-2 mr-2 text-white'>{disableButton ? 'Saving contract...' : 'Finish'}</span>
                                </button>
                            </>}
                        </div>
                    </div>
                </div>
                <div className='d-flex pl-4 pl-md-5 pt-md-2 justify-content-between flex-wrap'>
                    <div className='col pl-xl-5 pl-md-4 pl-3 col-md-3'>
                        <div className="pl-md-1 pl-xl-2 pl-0 pb-3 pb-md-0">
                            <h3>
                                <b>{quote.quoteName}</b>
                            </h3>
                            {quote.displayDescription && <div className='light-text mt-2'>{quote.description}</div>}
                        </div>
                    </div>
                    <div className='col-lg-5 col-md-8 col-12'>
                        <QuoteProgressBar />
                    </div>
                    <div className={finishingStage ? 'col-lg-3 col-md-6 align-self-center text-danger' : 'col-lg-3'}>
                        {finishingStage && <>
                            <span className='p-2'>{`Signature ${countOfSignaturesSigned} of ${totalSignatureCount}`}
                                <span className='d-none d-md-inline'> completed</span>
                            </span>
                            <ArrowButton onClick={() => goToNextAgreement(targetSignatureSlot + 1)} direction='down' />
                            <ArrowButton onClick={() => goToNextAgreement(targetSignatureSlot - 1)} direction='up' />
                        </>}
                    </div>
                </div>
            </div>
        </div>
        {showQuoteApprovedPopup && <QuoteApprovedPopup showQuoteApprovedPopup={showQuoteApprovedPopup} onClose={() => setShowQuoteApprovedPopup(false)} />}
    </>);
};
export default QuoteInfo;
