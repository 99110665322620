import React from 'react';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';

const Card = ({ children }) => {
    const { generatingPDF } = useReviewQuoteContext();

    return (<>
        <div className={`card-flex flex-wrap justify-content-between mb-4 ${generatingPDF ? '' : 'shadow-lg'}`}>
            <div className={generatingPDF ? 'border-0 card mb-2' : 'card mb-2'}>
                <div className='card-body'>

                    {children}

                </div>
            </div>
        </div>
    </>);
};
export default Card;
