import React from 'react';
import Col from 'react-bootstrap/Col';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';

const RespOrgText = ({ label, value, label2, value2, label3, value3, md, lg }) => {
    const { generatingPDF } = useReviewQuoteContext();
    return (
        <Col md={md} lg={lg} className={`${generatingPDF ? 'h3' : ''} mt-1` }><span className='font-weight-bold'>{label}:</span>
            <span className='pl-2'>{value}</span>
            {(label2 || value2) && <><span className='font-weight-bold'> {label2}:</span>
                <span className='pl-2'>{value2}</span></>}
            {(label3 || value3) && <><span className='font-weight-bold'> {label3}:</span>
                <span className='pl-2'>{value3}</span></>}
        </Col>
    );
};
export default RespOrgText;
