import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getEmptyLocation, formatPhoneNumber } from '../../helper';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';
import ReviewingPortingDisplay from '../quotes/ReviewingPortingDisplay';
import RespOrgText from '../common/RespOrgText';
import RespOrgCell from '../common/RespOrgCell';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CheckBox from '../common/CheckBox';
import Signature from './Signature';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare, faSquare } from '@fortawesome/free-regular-svg-icons';

const TollFreeAuthorization = ({ singlePage }) => {
    const [serviceLocation, setServiceLocation] = useState(getEmptyLocation());
    const { quote, contact, generatingPDF } = useReviewQuoteContext();

    useEffect(() => {
        const controller = new AbortController();
        if (quote.quoteID) {
            getServiceLocation(controller);
        }
        return () => controller.abort();
    }, [quote.quoteID]);

    const getServiceLocation = async (controller) => {
        const requestConfig = controller ? { signal: controller.signal } : null;
        const { data } = await axios.get(`/Api/Quotes/GetCore?route=/API/Quotes/GetQuoteLocations?quoteID=${quote.quoteID}&companyID=${quote.companyID}&addressType=Service`, requestConfig);
        if (controller.signal.aborted) {
            return;
        }
        const serviceAddress = data.locations.find(l => l.companyLocation?.addressType === 'Service');
        setServiceLocation(serviceAddress || getEmptyLocation());
    };

    return (<>
        <p>As the end-user subscriber, or the authorized representative of an end-user subscriber, of certain Toll Free service numbers   (the   "Customer"),
            I   hereby   authorize   Jivetel   Communications,ID   RHL21   to   be   the   Responsible   Organization  ("Resp  Org")
            for  the  following  Toll  Free  service  numbers,  including  acting  on  my  behalf,  and  at  my  direction,
            to transfer the Responsible Organization functions to Jivetel Communications Inc. </p>
        <Col lg={singlePage ? 12 : 7} md={singlePage ? 12 : 8} className='mt-3 mb-3'>
            <Row className='d-flex flex-wrap'>
                <RespOrgCell value='Current Rep Org Name' />
                <RespOrgCell value='Rep Org ID' />
                <RespOrgCell value='Fax Number' />
                <RespOrgCell value='Telephone Number' />
            </Row>
        </Col>
        <ReviewingPortingDisplay tollFree />
        <div className='avoid-page-break'><h5 className={singlePage ? 'text-center h4 text-decoration-underline' : ''}><b>Customer Information</b></h5>
            <Row className='d-flex flex-wrap mt-3 mb-3'>
                <RespOrgText md={12} label='Company Name' value={quote.company?.name} />
                {/* <Col sm={12} className='mt-1'><span className='font-weight-bold pr-2'>Address Type:</span> */}
                {/*    {(singlePage || generatingPDF) */}
                {/*        ? <><span> */}
                {/*            <span className='h3 mb-0'><FontAwesomeIcon icon={faSquare} /> */}
                {/*                <span className='ml-2'>Billing</span></span> */}
                {/*            <span className='h3 mb-0 ml-2'><FontAwesomeIcon icon={faCheckSquare} /> */}
                {/*                <span className='ml-2'>Service</span></span> */}
                {/*        </span></> */}
                {/*        : <span><CheckBox wrapperClass="form-check form-check-inline" label='Billing' id='billing-checkbox' labelClass='mb-0' checked={false} disabled /> */}
                {/*            <CheckBox wrapperClass="form-check form-check-inline" label='Service' id='service-checkbox' labelClass='mb-0' checked={true} disabled /></span>} */}
                {/* </Col> */}
                <RespOrgText md={12} label='Address' value={`${serviceLocation.address} ${serviceLocation.address2 ? `, ${serviceLocation.address2}` : ''}`} />
                <RespOrgText md={generatingPDF ? 12 : 5} label='City' value={serviceLocation.city}
                    label2='State' value2={serviceLocation.state}
                    label3='Zip' value3={serviceLocation.zipCode} />
                <RespOrgText md={12} label='Customer Contact' value={`${contact.firstName} ${contact.lastName}`}
                    label2={contact.phone ? 'Phone' : ''} value2={contact.phone ? formatPhoneNumber(contact.phone) : ''} />
            </Row>
        </div>
        <h5 className={singlePage ? 'h4' : ''}><b>Toll Free Portability-Disclaimer</b></h5>
        <p>I attest under penalty of law and as an authorized employee, or an authorized representative, of the Customer
            that the  Customer  is  the  exclusive  end-user  subscriber  of  the  Toll  Free  service  numbers  listed  above.</p>
        <p>Customer  assumes all liability for the use (including without limitation, authorized, fraudulent or misappropriated)
            of traffic of any other end-user subscriber with regards to the Toll Free service numbers listed.</p>
        <p>In addition, I understand that this request for a Resp Org change to Jivetel Communications Inc.
            does not constitute an order for disconnect of service with my existing carrier(s). </p>
        <p>I, on behalf of the Customer, continue to accept responsibility for notifying my existing  carrier(s)  of  any  intention
            to  disconnect  and/or  change  my  Toll  Free  service  after  designating  Jivetel  Communications as my Responsible Organization
            for the Toll Free numbers listed above and attachments. </p>
        <h5 className={singlePage ? 'text-center h4 text-decoration-underline' : ''}><b>New Resp Org Information</b></h5>
        <Row className='d-flex flex-wrap mt-3 mb-3'>
            <RespOrgText label='Resp Org ID' value='RHL21' md={singlePage ? 6 : 5} />
            <RespOrgText label='Name' value='Jivetel Communications Inc' md={singlePage ? 6 : 7} />
            <RespOrgText label='Contact Person' value='Victor Ovalle' md={singlePage ? 6 : 5} />
            <RespOrgText label='Email' value='sms@jivetel.com' md={singlePage ? 6 : 7} />
            <RespOrgText label='Phone No' value='855-444-JIVE' md={singlePage ? 6 : 5} />
            <RespOrgText label='Fax No' value='800-804-3471' md={singlePage ? 6 : 7} />
        </Row>
        <Signature name='tfAuthorization' singlePage={singlePage} />
        <Row className='d-flex flex-wrap mb-3 pl-3 mt-4'>
            <Col md={singlePage ? 5 : 4} className='align-self-end'>
                <Row>
                    <div className='h6 label my-0 align-self-end'>Print Name:</div>
                    <Col className='border-bottom ml-2'><p className='ml-2'>{contact.firstName} {contact.lastName}</p></Col>
                </Row>
            </Col>
            <Col md={{ span: singlePage ? 5 : 4, offset: singlePage ? 1 : 1 }} className='align-self-end'>
                <Row>
                    <div className='h6 label my-0'>Title:</div>
                    <Col className='border-bottom ml-2'></Col>
                </Row>
            </Col>
        </Row>
    </>
    );
};
export default TollFreeAuthorization;
