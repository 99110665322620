import React from 'react';
import Header from '../header/Header';
import '../header/css/assets.css';
import JiveCard from '../common/JiveCard';

const LinkExpired = () => {
    return (
        <>
            <Header />
            <JiveCard title='This Link Expired'>
                <p className="card-text mb-4 jive-blue">The link you clicked has expired or is no longer valid.</p>
                <p className="card-text mb-4 jive-blue">Please contact the person who shared the link with you for more information.</p>
            </JiveCard>
        </>
    );
};
export default LinkExpired;
