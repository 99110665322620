import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dateFormat from 'dateformat';
import { FormatCurrency } from '../common/FormatCurrency';
import List from 'devextreme-react/list';
import DataSource from 'devextreme/data/data_source';
import './css/paymentPlanDetails.css';

const PaymentPlanDetails = ({ quoteID, onTotalUpdate }) => {
    const [paymentPlanDetails, setPaymentPlanDetails] = useState([{}]);

    useEffect(() => {
        getPaymentPlanDetails();
    }, []);

    const dataSource = new DataSource({
        store: paymentPlanDetails,
        group: 'quoteID'
    });

    const getPaymentPlanDetails = async () => {
        const { data } = await axios.post(`/Api/Quotes/PostToCore?route=/API/Quotes/GetPaymentPlanDetails/?quoteID=${quoteID}`);
        setPaymentPlanDetails(data.result);
        onTotalUpdate({ subTotal: data.result[0]?.amount ?? 0 });
    };

    const renderDetails = (data, i) => {
        return <div className="d-flex flex-row justify-content-between">
            <div className=''>Payment #{i + 1} </div>
            <div>{FormatCurrency(data.amount)}</div>
        </div>;
    };

    const renderHeader = (data) => {
        return <div className="d-flex flex-row justify-content-between">
            <div><b>Payments</b></div>
            <div><b>Amount Due</b></div></div>;
    };

    return (<>
        {(paymentPlanDetails?.length
            ? <div className='pt-4'>
                <h5> <b>Non-Recurring Charges Payment Plan Detail</b></h5>
                <div className="list-container col-md-6">
                    <List
                        dataSource={dataSource}
                        height="100%"
                        itemRender={renderDetails}
                        groupRender={renderHeader}
                        grouped={true}
                    />
                </div>
                <p className='small p-1'>Please be aware that even though you have a payment plan set up, all applicable taxes will be charged on the first invoice.</p>
            </div>
            : <div></div>)}
    </>);
};
export default PaymentPlanDetails;
