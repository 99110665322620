import React from 'react';

const TextDisplay = ({ label, value }) => {
    return (
        <div className='form-group'>
            <dt className='title'>{label}</dt>
            <dd className='value'>{value}</dd>
        </div>
    );
};
export default TextDisplay;
