import React from 'react';

const AvoidPageBreak = ({ children, wrapperClass }) => {
    return (<>
        <div className={`${wrapperClass} avoid-page-break`}>
            {children}
        </div>
    </>);
};
export default AvoidPageBreak;
