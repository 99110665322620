import React from 'react';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';
import { FormatCurrency } from '../common/FormatCurrency';
import InlineText from '../common/InlineText';

const CostSavings = ({ currentMonthlyCost, jivetelMonthlyCost, jivetelInitialCost }) => {
    const { generatingPDF } = useReviewQuoteContext();
    return (
        <>
            <div className={generatingPDF ? 'h3' : 'h5'}><b>Savings:</b></div>
            <InlineText label='Current Monthly Cost' value={FormatCurrency(currentMonthlyCost)} />
            <InlineText label='Jivetel Monthly Cost' value={FormatCurrency(jivetelMonthlyCost)} />
            <InlineText label='Monthly Savings' value={FormatCurrency(currentMonthlyCost - jivetelMonthlyCost)} />
            <InlineText label='Yearly Savings' value={FormatCurrency((currentMonthlyCost - jivetelMonthlyCost) * 12)} />
            <InlineText label='ROI' value={`${Math.round(jivetelInitialCost / (currentMonthlyCost - jivetelMonthlyCost) * 100) / 100} months`} />
        </>
    );
};
export default CostSavings;
