import React from 'react';

const BoldDisplay = ({ label, value }) => {
    return (
        <div className='pt-4 pb-4 border-bottom'>
            <div className='d-flex flex-row justify-content-between mt-1'>
                <h5><b>{label}</b></h5>
                <h5><b>{value}</b></h5>
            </div>
            <div>
            </div>
        </div>
    );
};
export default BoldDisplay;
