import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { generatePath, useParams } from 'react-router-dom';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import { getStateAbbreviation } from '../common/States';
import TextInput from '../common/TextInput';
import ContactInputs from './ContactInputs';
import ContactCheckBoxes from './ContactCheckBoxes';
import { useReviewQuoteContext } from '../../ReviewQuoteContext.js';
import ContactDisplay from './ContactDisplay';
import TextDisplay from '../common/TextDisplay';
import CompanyDisplay from './CompanyDisplay';
import { getEmptyContact } from '../../helper';
import CheckBox from '../common/CheckBox';
import FullCompanyDisplay from './FullCompanyDisplay';

const CompanyInfoDisplay = ({ companyInfo, onCompanyInfoChange, primaryContact, onPrimaryContactChange, billingContact, onBillingContactChange }) => {
    const [isLoading, setIsLoading] = useState(true);
    const { quote, reviewingContract, generatingPDF, contractSigned, preApproval, signedQuote } = useReviewQuoteContext();
    const { quoteID } = quote;
    const urlQuoteID = useParams().quoteID;
    // const storage = window.localStorage;

    useEffect(() => {
        if (urlQuoteID) {
            getCompanyInfo();
        }
    }, [urlQuoteID, reviewingContract, preApproval]);

    useEffect(() => {
        if (!preApproval && urlQuoteID) {
            getPrimaryContact();
        }
    }, [preApproval, reviewingContract, quoteID]);

    const getCompanyInfo = async () => {
        setIsLoading(true);
        const { data } = await axios.get(`/Api/Quotes/GetCore?route=/API/Quotes/GetCompanyInfo?quoteID=${urlQuoteID}`);
        if (data.companyInfo) {
            const { name, listOfLocations, listOfCommunications: companyCommunications } = data.companyInfo;
            const serviceAddress = listOfLocations.find(l => l.companyLocation.addressType === 'Service');
            const billingAddress = listOfLocations.find(l => l.companyLocation.addressType === 'Billing');
            const companyName = name;
            const companyPhone = companyCommunications.find(c => c.communicationType === 'PhoneNumber')?.value;
            const faxNumber = companyCommunications.find(c => c.communicationType === 'FaxNumber')?.value;
            onCompanyInfoChange({
                ...data.companyInfo,
                companyName,
                companyPhone,
                faxNumber,
                serviceAddress: serviceAddress ? getLocationString(serviceAddress) : '',
                billingAddress: billingAddress ? getLocationString(billingAddress) : ''
            });
        }
        const contact = data.contact?.contact || getEmptyContact();
        const { firstName, lastName, listOfCommunications } = contact;
        const email = listOfCommunications.find(c => c.communicationType === 'EmailAddress');
        const phone = listOfCommunications.find(c => c.communicationType === 'PhoneNumber');
        const mobilePhone = listOfCommunications.find(c => c.communicationType === 'MobileNumber');
        const name = firstName || lastName ? `${firstName || ''} ${lastName || ''}` : '';
        onBillingContactChange({
            ...contact,
            name,
            email: email?.value,
            emailID: email?.commID,
            phone: phone?.value,
            phoneID: phone?.commID,
            mobilePhone: mobilePhone?.value,
            mobilePhoneID: mobilePhone?.commID,
            isAuthorized: data.contact?.isAuthorized,
            isSendStatement: data.contact?.isSendStatement,
            quoteContactTags: contact.contactID ? [] : ['Billing'],
            quoteContactID: data.contact?.quoteContactID

        });
        setIsLoading(false);
    };

    const getLocationString = (location) => {
        return `${location.address}, ${location.city}, ${location.state} ${location.zipCode}`;
    };

    const getPrimaryContact = async () => {
        const { data } = await axios.get(`/Api/Quotes/GetCore?route=/API/Quotes/GetCustomerPrimaryContact?quoteID=${urlQuoteID}`);
        const { contactID, firstName, lastName, listOfCommunications, isAuthorized, isSendStatement, quoteContactID } = data.primaryContact || getEmptyContact();
        const email = listOfCommunications.find(c => c.communicationType === 'EmailAddress');
        const phone = listOfCommunications.find(c => c.communicationType === 'PhoneNumber');
        const mobilePhone = listOfCommunications.find(c => c.communicationType === 'MobileNumber');
        onPrimaryContactChange({
            name: firstName || lastName ? `${firstName} ${lastName}` : '',
            email: email?.value,
            emailID: email?.commID,
            phone: phone?.value,
            phoneID: phone?.commID,
            mobilePhone: mobilePhone?.value,
            mobilePhoneID: mobilePhone?.commID,
            isAuthorized,
            isSendStatement,
            quoteContactTags: [],
            quoteContactID
        });
    };

    const onPrimaryContactTextChange = (e) => {
        // const primaryStorage = getStorage('primary-contact') ? JSON.parse(getStorage('primary-contact')) : {};
        // setStorage('primary-contact', JSON.stringify({ ...primaryStorage, [e.target.name]: e.target.value }));
        onPrimaryContactChange({ ...primaryContact, [e.target.name]: e.target.value });
    };

    const onBillingTextChange = (e) => {
        // const billingStorage = getStorage('billing-contact') ? JSON.parse(getStorage('billing-contact')) : {};
        // setStorage('billing-contact', JSON.stringify({ ...billingStorage, [e.target.name]: e.target.value }));
        onBillingContactChange({ ...billingContact, [e.target.name]: e.target.value });
    };

    const onPrimaryContactCheckBoxChange = (e) => {
        // const primaryStorage = getStorage('primary-contact') ? JSON.parse(getStorage('primary-contact')) : {};
        // setStorage('primary-contact', JSON.stringify({ ...primaryStorage, [e.target.name]: e.target.checked }));
        onPrimaryContactChange({ ...primaryContact, [e.target.name]: e.target.checked });
    };

    const onBillingCheckBoxChange = (e) => {
        // const billingStorage = getStorage('billing-contact') ? JSON.parse(getStorage('billing-contact')) : {};
        // setStorage('billing-contact', JSON.stringify({ ...billingStorage, [e.target.name]: e.target.checked }));
        onBillingContactChange({ ...billingContact, [e.target.name]: e.target.checked });
    };

    const showAsInputs = !reviewingContract && !generatingPDF && !preApproval;

    // const getStorage = (name) => {
    //    if (reviewingContract || contractSigned) {
    //        return '';
    //    }
    //    return storage.getItem(`${name}-${urlQuoteID}`) || '';
    // };

    // const setStorage = (name, value) => {
    //    storage.setItem(`${name}-${urlQuoteID}`, value);
    // };

    // const getPrimaryContactStorage = () => {
    //    const primaryFromStorage = getStorage('primary-contact');
    //    if (primaryFromStorage) {
    //        onPrimaryContactChange(JSON.parse(primaryFromStorage));
    //    } else {
    //        onPrimaryContactChange(getEmptyContact());
    //    }
    // };

    const onCompanyNameChange = (e) => {
        // setStorage('companyName', e.target.value);
        onCompanyInfoChange({ ...companyInfo, [e.target.name]: e.target.value });
    };

    const onSameAsPrimaryChange = (e) => {
        let contact = getEmptyContact();
        if (e.target.checked) {
            contact = { ...primaryContact };
        }
        onBillingContactChange({ ...contact, quoteContactTags: billingContact.quoteContactTags });
        // setStorage('billing-contact', JSON.stringify(contact));
    };

    return (
        <>
            <div className='bg-gray-100 rounded-card'>
                <div className="row m-1 p-lg-3 ">
                    <div className="col-12 pt-3 pt-lg-1">
                        <h6><b>Company Information</b></h6>
                    </div>
                    {isLoading && <div className='container mt-2 mb-4 d-flex justify-content-center'>
                        <LoadIndicator id="large-indicator" height={60} width={60} />
                    </div>}
                    {!isLoading && <>
                        {showAsInputs && <><div className='col-12 col-lg-6 col-xl-3 mt-4 mb-2'>
                            <TextInput
                                tabIndex={1}
                                required
                                value={companyInfo.companyName}
                                name='companyName'
                                label='Company Name (Legal Company Name)'
                                placeholder='Company Name'
                                onChange={onCompanyNameChange} />
                        </div>
                            <div className="clearfix col-12"></div>
                            <div className='col-lg-6 col-12 border-right-lg-bold pr-lg-4 order-0'>
                                <h6 className='mb-3'>Primary Contact</h6>
                                <ContactInputs contact={primaryContact} onTextChange={onPrimaryContactTextChange} baseTabIndex={1} />
                            </div>
                            <div className='col-lg-6 col-12 pl-lg-4 order-lg-1 order-2 mt-lg-0 mt-md-1 mt-2 pt-3 pt-lg-0'>
                                <div className='d-flex flex-wrap mb-2 mb-md-0'>
                                    <div className='col-lg-6 col-12 pl-0'>
                                        <h6 className='mb-3'>Billing Contact</h6>
                                    </div>
                                    <div className='col-lg-6 col-12 p-0 pl-lg-3'>
                                        <CheckBox
                                            label='Same as primary'
                                            id='same-as-primary'
                                            onChange={onSameAsPrimaryChange}
                                            labelClass='mb-0'
                                        />
                                    </div>
                                </div>
                                <ContactInputs contact={billingContact} onTextChange={onBillingTextChange} baseTabIndex={7} />
                            </div>
                            <div className='col-lg-6 col-12 border-right-lg-bold order-lg-2 order-1'>
                                <ContactCheckBoxes contact={primaryContact} onContactCheck={onPrimaryContactCheckBoxChange} baseTabIndex={5} />
                            </div>
                            <div className='col-lg-6 col-12  pl-lg-4 order-3 mb-2 mb-lg-0'>
                                <ContactCheckBoxes contact={billingContact} onContactCheck={onBillingCheckBoxChange} baseTabIndex={11} />
                            </div>
                        </>}
                        {!showAsInputs && <>
                            <div className="clearfix col-12 mt-2"></div>
                            <div className="col-12">
                                <div className="px-3 mt-2">
                                    <div className="row">
                                        {preApproval && <FullCompanyDisplay company={companyInfo} />}
                                        {!preApproval && <>
                                            <div className='col-lg-5 col-12 order-0'>
                                                <CompanyDisplay company={companyInfo} />
                                            </div>
                                            <div className={`${preApproval ? 'col-lg-7' : 'col-lg-4'} col-12  order-lg-1 order-1`}>
                                                <ContactDisplay contact={primaryContact} contactType='Primary' />
                                            </div>
                                            <div className='col-lg-3 col-12  order-lg-2 order-2 '>
                                                <ContactDisplay contact={billingContact} contactType='Billing' />
                                            </div>
                                        </>}
                                    </div>
                                </div>
                            </div>
                        </>}
                    </>}
                </div>
            </div>
        </>
    );
};
export default CompanyInfoDisplay;
