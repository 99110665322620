import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Collapser = ({ fullView, onChange }) => {
  const onAngeleClick = () => {
    onChange(!fullView);
  };
  return (
        <button type="button"
            onClick={onAngeleClick}
            className="btn btn-icon shadow-none">
            <FontAwesomeIcon icon={`fal fa-2x mt-2 ${fullView ? 'fa-solid fa-angle-down' : 'fa-solid fa-angle-right'}`} />
        </button>
  );
};
export default Collapser;
