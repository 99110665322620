import React, { useState } from 'react';
import JiveCard from '../common/JiveCard';
import CustomerSelect from './CustomerSelect';
import Submit from './Submit';
import SubscribedEmails from './SubscribedEmails';

const UnsubscribeCard = ({ data, emailAddress, submitForm, updateData }) => {
    const customerNames = [...new Set(data.map(d => d.customerName))].filter(customer => customer !== null).map(customer => {
        return { text: customer, value: customer };
    });
    const notificationTypes = [{ name: 'Yom Tov Message Reminder', id: 1 }, { name: 'Legal Holiday Message Reminder', id: 3 }];
    const [selectedCustomerName, setSelectedCustomerName] = useState('');
    const [selectedCustomerID, setSelectedCustomerID] = useState('');

    const handleCustomerSelect = customer => {
        setSelectedCustomerName(customer);
        setSelectedCustomerID(data.find(d => d.customerName === customer)?.customerID);
    };
    const newNotificationObj = (id, typeID) => {
        return {
            uniqueID: id,
            emailNotificationTypeID: typeID,
            action: 'Notification',
            customerID: selectedCustomerID,
            customerName: selectedCustomerName,
            emailAddress: emailAddress,
            subscribed: true,
            category: 'Notification',
            emailAction: 'Notifications'
        };
    };
    const handleEmailActionChecked = (id, typeID) => {
        const inData = data.find(d => d.uniqueID === id || d.uniqueID === typeID);
        if (!inData) {
            updateData([...data, newNotificationObj(id, Number(typeID))]);
        } else {
            updateData(data.map(d => {
                if (d.uniqueID === id) {
                    return { ...d, subscribed: !d.subscribed };
                } else if (d.uniqueID === typeID) {
                    return { ...d, sendReceipt: !d.sendReceipt };
                } else {
                    return d;
                }
            }));
        }
    };
    const handleSelectAllCheckBox = selectAll => {
        updateData(data.map(d => {
            if (d.customerID === selectedCustomerID || d.action === 'Tickets') {
                return { ...d, subscribed: selectAll };
            } else {
                return d;
            }
        }));
        if (selectAll) {
            const currentNotifications = data.filter(d => d.action === 'Notification' && d.customerID === selectedCustomerID);
            if (currentNotifications.length < notificationTypes.length) {
                const newNotifications = notificationTypes.map(n => {
                    if (!currentNotifications.find(cn => cn.emailNotificationTypeID === n.id)) {
                        return newNotificationObj(`newnt${n.id}${selectedCustomerID}`, n.id);
                    } else {
                        return '';
                    }
                }).filter(d => d !== '');
                updateData([...data, ...newNotifications]);
            }
        }
    };
    const handleSubmit = () => {
        submitForm(selectedCustomerName);
    };
    return (
        <>
            <JiveCard>
                <p>{emailAddress} is subscribed to the following. Uncheck to unsubscribe.</p>
                {customerNames.length > 0 && <CustomerSelect
                    customerNames={customerNames}
                    handleCustomerSelect={handleCustomerSelect}
                    selectedCustomer={selectedCustomerName}
                />}
                <SubscribedEmails
                    data={data}
                    handleEmailActionChecked={handleEmailActionChecked}
                    handleSelectAllCheckBox={handleSelectAllCheckBox}
                    customerName={selectedCustomerName}
                    customerID={selectedCustomerID}
                    notificationTypes={notificationTypes}
                />
                <Submit handleSubmit={handleSubmit} />
            </JiveCard>
        </>
    );
};

export default UnsubscribeCard;
