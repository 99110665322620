import React from 'react';
import InvalidFeedback from './InvalidFeedback';

const TextInput = ({ name, label, onChange, placeholder, value, type = 'text', required = false, pattern, tabIndex, showLabel = true }) => {
    const wrapperClass = 'form-group';

    const getMessage = () => {
        if (value && type === 'email') {
            return "That's not a valid email";
        } else if (value && name === 'name') {
            return 'Please enter a first and last name';
        }
        return 'This is a required field';
    };

    return (
        <div className={wrapperClass}>
            {showLabel && <label htmlFor={name}>{label ?? placeholder}</label>}
            <div className="field">
                <input
                    tabIndex={tabIndex}
                    required={required}
                    type={type}
                    name={name}
                    className='form-control'
                    placeholder={placeholder}
                    value={value || ''}
                    pattern={pattern}
                    onChange={onChange}
                    autoComplete='-1' />
                <InvalidFeedback message={getMessage()} />
            </div>
        </div>
    );
};

export default TextInput;
