import React from 'react';
import JiveCard from '../components/common/JiveCard';
import Header from '../components/header/Header';

const Error = () => {
    return (
        <>
            <Header />
            <JiveCard title='Error'>
                <p className="card-text mb-4 jive-blue">There was an error while trying to process your request.
                    We are sorry for the inconvience.</p>
            </JiveCard>
        </>
    );
};
export default Error;
