import React from 'react';
import ReactTooltip from 'devextreme-react/tooltip';

const Tooltip = ({ message, target, width, height }) => {
    return (
        <>
            <ReactTooltip showEvent="mouseenter"
                hideEvent="mouseleave"
                position="top"
                width={width}
                height={height}
                hideOnOutsideClick={false}
                target={target}>
                {message}
            </ReactTooltip>
        </>
    );
};
export default Tooltip;
