import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import CheckBox from '../common/CheckBox';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';
import { getEmptyLocation } from '../../helper';
import LocationInputs from './LocationInputs';

const LocationsDisplay = ({ serviceLocation, onServiceLocationChange, billingLocation, onBillingLocationChange }) => {
    const [isLoading, setIsLoading] = useState(true);
    const { quote, reviewingContract, generatingPDF, contractSigned, preApproval, signedQuote } = useReviewQuoteContext();
    // const storage = window.localStorage;
    const urlQuoteID = useParams().quoteID;

    useEffect(() => {
        if (!reviewingContract && !preApproval) {
            getServiceLocation();
            getBillingLocation();
        }
    }, [quote.quoteID, reviewingContract, preApproval]);
    const getServiceLocation = async () => {
        setIsLoading(true);
        const { data } = await axios.get(`/Api/Quotes/GetCore?route=/API/Quotes/GetQuoteLocations?quoteID=${quote.quoteID}&companyID=${quote.companyID}&addressType=Service`);
        const serviceAddress = data.locations[0] || {};
        // const serviceStorage = getStorage('service-location') ? JSON.parse(getStorage('service-location')) : {};
        onServiceLocationChange({
            ...serviceAddress,
            address: serviceAddress.address,
            address2: serviceAddress.address2 || '',
            city: serviceAddress.city,
            state: serviceAddress.state,
            zipCode: serviceAddress.zipCode,
            country: serviceAddress.country || 'United States',
            locationID: serviceAddress.locationID,
            addressType: 'Service'
        }, true);
        setIsLoading(false);
    };

    const getBillingLocation = async () => {
        setIsLoading(true);
        const { data } = await axios.get(`/Api/Quotes/GetCore?route=/API/Quotes/GetCustomerBillingLocation?quoteID=${quote.quoteID}&companyID=${quote.companyID}`);
        if (data.location) {
            onBillingLocationChange({ ...data.location, addressType: 'Billing' }, true);
        }
        setIsLoading(false);
    };

    const onServiceLocationTextChange = (e) => {
        // const serviceStorage = getStorage('service-location') ? JSON.parse(getStorage('service-location')) : {};
        // setStorage('service-location', { ...serviceStorage, [e.target.name]: e.target.value });
        onServiceLocationChange({ ...serviceLocation, [e.target.name]: e.target.value });
    };

    const onBillingLocationTextChange = (e) => {
        // const billingStorage = getStorage('billing-location') ? JSON.parse(getStorage('billing-location')) : {};
        // setStorage('billing-location', { ...billingStorage, [e.target.name]: e.target.value });
        onBillingLocationChange({ ...billingLocation, [e.target.name]: e.target.value });
    };

    const onSameAsServiceChange = (e) => {
        let location = getEmptyLocation();
        if (e.target.checked) {
            location = { ...serviceLocation, locationID: 0, addressType: 'Billing' };
        }
        onBillingLocationChange(location);
        // setStorage('billing-location', location);
    };

    // const getStorage = (name) => {
    //    if (reviewingContract || contractSigned) {
    //        return '';
    //    }
    //    return storage.getItem(`${name}-${urlQuoteID}`) || '';
    // };

    // const setStorage = (name, value) => {
    //    storage.setItem(`${name}-${urlQuoteID}`, JSON.stringify(value));
    // };

    // const getBillingLocationStorage = () => {
    //    let billingStorage = getStorage('billing-location');
    //    if (billingStorage) {
    //        billingStorage = JSON.parse(billingStorage);
    //        if (!billingStorage.country) {
    //            billingStorage.country = 'United States';
    //        }
    //        onBillingLocationChange(billingStorage);
    //    }
    // };
    const showInputs = !reviewingContract && !generatingPDF && !preApproval;

    return (
        <>{showInputs && <div className='bg-gray-100 rounded-card'>
            <div className="row m-1 p-lg-3 ">
                <div className="col-12 pt-3 pt-lg-1">
                    <h6><b>Address Information</b></h6>
                </div>
                {isLoading && <div className='container mt-2 mb-4 d-flex justify-content-center'>
                    <LoadIndicator id="large-indicator" height={60} width={60} />
                </div>}
                {!isLoading && <div className='col-12'><div className='row'>
                    <div className='col-lg-6 col-12 border-right-lg-bold order-0 pr-lg-4'>
                        <h6 className='mb-3 mt-3'>Service Address</h6>
                        <LocationInputs location={serviceLocation} onTextChange={onServiceLocationTextChange} baseTabIndex={13} />
                    </div>
                    <div className='col-lg-6 col-12 order-1 mt-lg-0 mt-md-1 mt-2 pt-3 pt-lg-0 pl-lg-4 pb-3 pb-md-0'>
                        <div className='d-flex  align-items-center flex-wrap mb-2 mb-md-0'>
                            <div className='col-lg-6 col-md-6 col-12 pl-0'>
                                <h6 className='mb-3 mt-3'>Billing Address</h6>
                            </div>
                            {!reviewingContract && <div className='col-lg-6 col-12 pl-0 pl-lg-3'>
                                <CheckBox
                                    label='Same as service'
                                    id='same-as-service'
                                    onChange={onSameAsServiceChange}
                                    labelClass='mt-2' />
                            </div>}
                        </div>
                        <LocationInputs location={billingLocation} onTextChange={onBillingLocationTextChange} baseTabIndex={19} />
                    </div>
                </div>
                </div>}
            </div>
        </div>}

        </>
    );
};
export default LocationsDisplay;
