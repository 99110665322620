import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const QuoteErrorModal = ({ show }) => {
    return (<>
        <Modal show={show} enforceFocus="false" className='modal-dark-bg' centered>
            <Modal.Header className='bg-primary-600'>
                <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <small className='text-muted'>Sorry we are experiencing some issues please restart the signup process....</small>
                <div className='d-flex justify-content-center m-3'>
                    <div className='text-danger'>
                        <FontAwesomeIcon icon="fa-solid fa-times-circle" size='5x' />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-blue small-button' onClick={() => window.location.reload()}><span className='text-white'>Restart</span></button>
            </Modal.Footer>
        </Modal>
    </>
    );
};
export default QuoteErrorModal;
