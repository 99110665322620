import React from 'react';
import Header from '../header/Header';
import '../header/css/assets.css';
import JiveCard from '../common/JiveCard';
import Tooltip from '../common/Tooltip';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const QuoteManuallySigned = ({ onDownload }) => {
    return (
        <>
            <Header />
            <JiveCard title='Quote Manually Signed'>
                <p className="card-text mb-4 jive-blue">The quote was signed by paper and therefore cannot be viewed online.</p>
                <p className="card-text mb-4 jive-blue">You can view the quote by downloading it.
                    <Button size='sm'
                        id='download'
                        onClick={onDownload}
                        className='ml-2 bg-jive-blue'>
                        <span className='text-light'>
                            <FontAwesomeIcon icon="fa-solid fa-download" />
                            <Tooltip message='Download quote'
                                target='#download'></Tooltip>
                        </span>
                    </Button>
                </p>
            </JiveCard>

        </>
    );
};
export default QuoteManuallySigned;
