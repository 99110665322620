import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import QuoteInfo from '../components/quotes/QuoteInfo';
import Charges from '../components/quotes/Charges';
import Totals from '../components/quotes/Totals';
import Notes from '../components/quotes/Notes';
import PaymentInfo from '../components/payment/PaymentInfo';
import axios from 'axios';
import dateFormat from 'dateformat';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PortingTable from '../components/quotes/PortingTable';
import Card from '../components/common/Card';
import 'devextreme/dist/css/dx.light.css';
import '../css/print.css';
import HeaderAgreement from '../components/legal/HeaderAgreement';
import { useReviewQuoteContext } from '../ReviewQuoteContext';
import PaymentPlanDetails from '../components/payment/PaymentPlanDetails';
import AgreementContainer from '../components/legal/AgreementContainer';
import CompanyInfoWrapper from '../components/quotes/CompanyInfoWrapper';
import TOSAgreement from '../components/legal/TOSAgreement';
import ElevatorAndFireAlarm from '../components/legal/ElevatorAndFireAlarm';
import CustomerAcknowledgement from '../components/legal/CustomerAcknowledgement';
import AuthorizationLetter from '../components/legal/AuthorizationLetter';
import TollFreeAuthorization from '../components/legal/TollFreeAuthorization';
import { Popup } from 'devextreme-react/popup';
import { capitalizeFirstLetter, notify } from '../helper';
import ContractSentModal from '../components/quotes/ContractSentModal';
import SignatureModal from '../components/quotes/SignatureModal';
import { LoadIndicator } from 'devextreme-react/load-indicator';
import Error from './Error';
import LinkExpired from '../components/quotes/LinkExpired';
import ContractSigned from '../components/quotes/ContractSigned';
import QuoteManuallySigned from '../components/quotes/QuoteManuallySigned';
import { LoadPanel } from 'devextreme-react/load-panel';
import ReviewingChargesDisplay from '../components/quotes/ReviewingChargesDisplay';
import { FormatCurrency } from '../components/common/FormatCurrency';
import ReviewingPortingDisplay from '../components/quotes/ReviewingPortingDisplay';
import logo from '../images/jivetel-logo.png';
import BoldDisplay from '../components/common/BoldDisplay';
import EmailPromptModal from '../components/quotes/EmailPromptModal';
import AvoidPageBreak from '../components/common/AvoidPageBreak';
import CostAnalysis from '../components/quotes/CostAnalysis';
import QuoteErrorModal from '../components/quotes/QuoteErrorModal';

const ReviewQuote = () => {
    require('../css/custom.css'); // using require instead of import since I want this css to be scoped to only this componenet - not other non scoped componenets //
    const {
        quote, generatingPDF, reviewingContract, contractSigned,
        setContractSigned, showAgreements, preApproval, signedQuote, contact,
        agreements, setAgreements, targetSignatureSlot, setTargetSignatureSlot
    } = useReviewQuoteContext();
    const [totals, setTotals] = useState({ subTotalNrc: 0, taxNrc: 0, subTotalMrc: 0, taxMrc: 0, firstMonthPaymentPlan: 0 });
    const [hashValidated, setHashValidated] = useState(false);
    const [showSignatureModal, setShowSignatureModal] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isPaymentRequired, setIsPaymentRequired] = useState(true);
    const [popup, setPopup] = useState({ show: false, errorMessages: [], title: '', helperText: '' });
    const [showContractModal, setShowContractModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showQuoteErrorModal, setShowQuoteErrorModal] = useState(false);
    const [numPages, setNumPages] = useState(0);
    const [hasAdditionalNumbers, setHasAdditionalNumbers] = useState(quote?.additionalPortingNumbers || false);
    const [savingData, setSavingData] = useState(false);
    const [savingContract, setSavingContract] = useState(false);
    const [loader, setLoader] = useState({ show: false, message: '' });
    const [isPortingNumbers, setIsPortingNumbers] = useState({ isPorting: true, isTollFreePorting: true });
    const [showTaxAlert, setShowTaxAlert] = useState(false);
    const urlQuoteID = useParams().quoteID;
    const urlHash = useParams().hash;
    const emailHash = useParams().emailHash;
    const navigate = useNavigate();
    const componentRef = useRef(null);
    const paymentFormRef = useRef(null);
    const paymentButtonRef = useRef(null);
    const submitButtonRef = useRef(null);

    // function to figure out how many pages we shall need
    // function handlePrint () {
    //   const componentHeight = componentRef.current.offsetHeight;
    //   const printableHeight = window.innerHeight;
    //   const numPages = Math.ceil(componentHeight / printableHeight);
    //   setNumPages(numPages);
    // }
    function handlePrint () {
        const component = componentRef.current;
        const style = window.getComputedStyle(component);
        const paddingTop = parseFloat(style.getPropertyValue('padding-top'));
        const paddingBottom = parseFloat(style.getPropertyValue('padding-bottom'));
        const borderTop = parseFloat(style.getPropertyValue('border-top-width'));
        const borderBottom = parseFloat(style.getPropertyValue('border-bottom-width'));
        const componentHeight = component.offsetHeight - paddingTop - paddingBottom - borderTop - borderBottom;
        const printableHeight = window.innerHeight;
        const numPages = Math.ceil(componentHeight / printableHeight);
        setNumPages(numPages);
    }

    useEffect(() => {
        validateHash();
    }, [hashValidated]);

    useEffect(() => {
        axios.get(`/Api/Quotes/GetCore?route=/API/Quotes/IsPortingNumbers?quoteID=${urlQuoteID}&projectID=${quote.projectID || 0}`).then(data => {
            const isPorting = data.data.isPortingNumbers > 0;
            const isTollFreePorting = data.data.isTollFreePortingNumbers > 0;
            setIsPortingNumbers({ isPorting, isTollFreePorting });
            if (quote.quoteID) {
                filterAndSetRefs(isPorting && quote.portingNumbers, isTollFreePorting && quote.portingNumbers);
            }
        });
    }, [urlQuoteID, quote.quoteID]);

    useEffect(() => {
        setHasAdditionalNumbers(quote?.additionalPortingNumbers || false);
    }, [quote.additionalPortingNumbers]);

    useEffect(() => {
        setLoader({ show: savingData, message: savingData ? 'Saving data...' : '' });
    }, [savingData]);

    const filterAndSetRefs = (includePorting, includeTollFreePorting) => {
        setAgreements({
            tos: agreements.tos,
            elavator: agreements.elavator,
            acknowledgement: agreements.acknowledgement,
            ...(includePorting) && { authorization: agreements.authorization },
            ...(includeTollFreePorting) && { tfAuthorization: agreements.tfAuthorization }
        });
    };

    const validateHash = async () => {
        const { data } = await axios.post('/Api/Quotes/PostToCore?route=/API/Quotes/ValidateQuoteHash', { quoteID: urlQuoteID, hash: urlHash });
        setIsPaymentRequired(data.isPaymentRequired);
        if (data.isValid) {
            setHashValidated(true);
        }
        setIsLoading(false);
    };

    const onFinishClick = async () => {
        const unsignedAgreements = Object.keys(agreements).filter(k => !agreements[k].signed);
        if (unsignedAgreements.length) {
            scrollToAgreement(unsignedAgreements);
            const errorMessages = unsignedAgreements.map(u => agreements[u].title);
            setPopup({
                show: true,
                errorMessages,
                title: 'Required Signatures',
                helperText: `The following signature${errorMessages.length > 1 ? 's are' : ' is'} required before you finish your quote.`
            });
        } else {
            if (contact.email) {
                saveContract();
            } else {
                setShowEmailModal(true);
            }
        }
    };

    const saveContract = async () => {
        setSavingContract(true);
        setLoader({ show: true, message: 'Saving contract...' });
        const quoteValidated = await validateQuote();
        if (!quoteValidated) {
            setSavingContract(false);
            setLoader({ show: false, message: '' });
            setShowQuoteErrorModal(true);
            return;
        }
        await updateHasAddtionalPorting();
        const data = await setQuoteSigned();
        if (data.result) {
            setShowContractModal(true);
            setContractSigned(true);
        } else {
            notify(data.message, 'error');
        }
        setSavingContract(false);
        setLoader({ show: false, message: '' });
    };

    const scrollToAgreement = (unsginedAgreements) => {
        const firstOne = unsginedAgreements[0];
        agreements[firstOne]?.ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    };

    const setQuoteSigned = async () => {
        const { data } = await axios.post('/Api/Quotes/PostToCore?route=/API/Quotes/SetQuoteSigned', { quoteID: quote.quoteID, contactID: contact.contactID });
        return data;
    };

    const updateHasAddtionalPorting = async () => {
        const { data } = await axios.post('/Api/Quotes/PostToCore?route=/API/Quotes/UpdateAdditionalPortingNumbers',
            { quoteID: urlQuoteID, additionalPortingNumbers: hasAdditionalNumbers });
    };

    const renderPopup = () => {
        return <div>
            <p className='text-muted fs-6 fw-light'>{popup.helperText}</p>
            {popup.errorMessages.map((m, i) => <div key={i} className='alert alert-danger mb-2' title={m}>{capitalizeFirstLetter(m)}</div>)}
        </div>;
    };

    const onDownload = async () => {
        setLoader({ show: true, message: 'Preparing PDF...' });
        const { data } = await axios.get(`/Api/Quotes/DownloadQuote?route=/api/Quotes/GetQuotePDF?quoteID=${quote.quoteID}&showAgreements=${contractSigned}`, {
            responseType: 'arraybuffer'
        });
        const blob = new Blob([data], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${quote.quoteName} quote ${quote.quoteID}.pdf`;
        document.body.appendChild(a);
        a.click();
        setLoader({ show: false, message: '' });
    };

    const onSetSignatureCallBack = () => {
        notify('Your signature was saved.');
        window.scrollTo(0, 0);
    };

    const showTOS = generatingPDF ? showAgreements : reviewingContract && !preApproval;

    const showPortingNumbers = !reviewingContract && quote.portingNumbers && !preApproval;

    const showPayment = isPaymentRequired && !preApproval && (!generatingPDF || contractSigned);

    const onFormSubmitCompleteion = (sign) => {
        setSavingData(false);
        if (sign) {
            setShowSignatureModal(true);
        } else {
            notify('Data saved for later.');
        }
    };

    const validateQuote = async () => {
        const { data } = await axios.get(`/Api/Quotes/DownloadQuote?route=/api/Quotes/ValidateQuote?quoteID=${quote.quoteID}`);
        return data.quoteValidated;
    };

    if (contractSigned && !signedQuote.signatureBase64) {
        return <QuoteManuallySigned onDownload={onDownload} />;
    }
    // console.log(contractSigned);
    // if (contractSigned) {
    //     return <ContractSigned />;
    // }
    return (
        <>
            { /* <p style={{ color: 'blue', lineHeight: 10, padding: 20, minHeight: '100px' }}>Reviewing QuoteID {urlQuoteID}</p> */}
            {hashValidated &&
                <div className={generatingPDF ? 'row' : 'row bg-gray-100'} ref={componentRef}>
                    {
                        !generatingPDF && <QuoteInfo
                            disableButton={savingData || savingContract}
                            onFinishClick={onFinishClick}
                            submitButtonRef={submitButtonRef} />
                    }
                    {!isLoading && <div className="col-12">
                        <div className='d-flex justify-content-center align-items-center row flex-row'>
                            <div className={generatingPDF ? 'col-12 p-0' : 'col-xl-9 col-12'}>{/* needs to be swapped to when printing is true */}
                                <div className={generatingPDF ? 'card bg-darken-sm border-0' : 'card bg-darken-sm mb-4 pb-2 shadow-lg'}>

                                    {!generatingPDF && <div className='bg-gray-100 p-5'>
                                        <div className='row'>
                                            <div className='col-xl-3 col-2 order-0 mb-xl-0 mb-3'>
                                                <a href='https://www.jivetel.com/' target='_blank' rel="noreferrer">
                                                    <img className='img-fluid' src={logo} />
                                                </a>
                                            </div>
                                            <div className='col-lg-6  align-self-center col-12 order-2 order-lg-1'>
                                                <h4>
                                                    <span className=''><b>{quote.quoteName}</b></span>
                                                </h4>
                                                <div className='light-text'>{dateFormat(new Date(), 'mmmm dS, yyyy')}</div>
                                            </div>
                                            <div className='col-1 col-md-3 col-lg-3 offset-7 offset-md-6 offset-lg-0 order-1 align-items-end order-lg-3 align-self-center'>
                                                <button title="Download quote" className='btn btn-light btn-icon btn-white align-right' onClick={onDownload}>
                                                    <span className='grey-text'> <FontAwesomeIcon icon="fa-solid fa-download" /></span>
                                                    <span className='pl-2 grey-text d-none d-md-inline'>Download All</span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>}
                                    {showTOS && <div className='px-4'><AgreementContainer header='Service Order'>
                                        <HeaderAgreement
                                            companyName={quote.company?.name} >
                                        </HeaderAgreement>
                                    </AgreementContainer></div>}
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <CompanyInfoWrapper
                                                    onFormSubmitted={{
                                                        before: () => setSavingData(true),
                                                        after: onFormSubmitCompleteion
                                                    }}
                                                    onPopupChange={setPopup}
                                                    paymentRequired={isPaymentRequired}
                                                    paymentFormRef={paymentFormRef}
                                                    paymentButtonRef={paymentButtonRef}
                                                    submitButtonRef={submitButtonRef}
                                                    onServiceAddressChanged={setShowTaxAlert} />
                                                {reviewingContract || generatingPDF
                                                    ? <div>
                                                        <AvoidPageBreak wrapperClass='mt-5'>
                                                            <ReviewingChargesDisplay quoteID={urlQuoteID}
                                                                isRecurring={true}
                                                                title="Monthly Recurring Charges"
                                                                onTotalUpdate={(t) => setTotals(totals => ({ ...totals, subTotalMrc: t.subTotal, taxMrc: t.tax }))} />
                                                        </AvoidPageBreak>
                                                        <AvoidPageBreak wrapperClass='border-bottom pt-4 pb-4 pr-2 mr-1 '>
                                                            <Totals subTotal={totals.subTotalMrc} tax={totals.taxMrc} totalHeader="Total Monthly Recurring Charges" />
                                                        </AvoidPageBreak>
                                                        <AvoidPageBreak>
                                                        <AvoidPageBreak wrapperClass='pt-5 pb-0'>
                                                            <ReviewingChargesDisplay quoteID={urlQuoteID}
                                                                isRecurring={false}
                                                                title="Non-Recurring Charges"
                                                                onTotalUpdate={(t) => setTotals(totals => ({ ...totals, subTotalNrc: t.subTotal, taxNrc: t.tax, filteredCharges: t.filteredCharges }))} />
                                                        </AvoidPageBreak>
                                                        <AvoidPageBreak wrapperClass='border-bottom pt-4 pb-4 pr-2 mr-1'>
                                                            <Totals subTotal={totals.subTotalNrc} tax={totals.taxNrc} totalHeader="Total Non-Recurring Charges" />
                                                        </AvoidPageBreak>
                                                        </AvoidPageBreak>
                                                    </div>
                                                    : <div>
                                                        {!preApproval && showTaxAlert && <div className="alert alert-warning alert-dismissible fade show mt-3">Due to location change, the tax estimates may not be accurate. You will have a chance to review updated tax information upon signing the contract.
                                                            <button type='button' className='close' onClick={() => setShowTaxAlert(false)} data-dismiss='alert'><FontAwesomeIcon icon='fa-times' size='xs' /></button></div>}
                                                        <AvoidPageBreak wrapperClass='mt-5 pb-4'>
                                                            <Charges
                                                                quoteID={urlQuoteID}
                                                                isRecurring={true}
                                                                title="Monthly Recurring Charges"
                                                                onTotalUpdate={(t) => setTotals(totals => ({ ...totals, subTotalMrc: t.subTotal, taxMrc: t.tax }))}
                                                            />
                                                        </AvoidPageBreak>
                                                        <AvoidPageBreak wrapperClass='border-bottom pb-4'>
                                                            <Totals subTotal={totals.subTotalMrc} tax={totals.taxMrc} totalHeader="Total Monthly Recurring Charges" />
                                                        </AvoidPageBreak>
                                                        <AvoidPageBreak wrapperClass='pt-4 pb-4'>
                                                            <Charges
                                                                quoteID={urlQuoteID}
                                                                isRecurring={false}
                                                                title="Non-Recurring Charges"
                                                                onTotalUpdate={(t) => setTotals(totals => ({ ...totals, subTotalNrc: t.subTotal, taxNrc: t.tax, filteredCharges: t.filteredCharges }))}
                                                            />
                                                        </AvoidPageBreak>
                                                        <AvoidPageBreak wrapperClass='border-bottom pb-4'>
                                                            <Totals subTotal={totals.subTotalNrc} tax={totals.taxNrc} totalHeader="Total Non-Recurring Charges" />
                                                        </AvoidPageBreak>
                                                    </div>}
                                                {totals.firstMonthPaymentPlan > 0
                                                    ? <div></div>
                                                    : <BoldDisplay
                                                    label='Total Initial Charges'
                                                    value={FormatCurrency(totals.filteredCharges + totals.taxNrc + totals.firstMonthPaymentPlan)} />}
                                                <BoldDisplay
                                                    label='Contract Term'
                                                    value={`${quote.contractTerm} Years`}
                                                />
                                                <AvoidPageBreak>
                                                    <PaymentPlanDetails
                                                        quoteID={urlQuoteID}
                                                        onTotalUpdate={(t) => setTotals(totals => ({ ...totals, firstMonthPaymentPlan: t.subTotal }))} />
                                                </AvoidPageBreak>
                                                <AvoidPageBreak wrapperClass='mt-4'>
                                                    <p className='small p-1'>This quote is valid for 30 days from the date it was presented.
                                                        Due to dramatic fluctuations in costs,
                                                        additional services or equipment added in the future will be priced at
                                                        the time of the request and may differ from the pricing on this quote.</p>
                                                </AvoidPageBreak>
                                                <AvoidPageBreak>
                                                    <CostAnalysis totalMrc={totals.subTotalMrc + totals.taxMrc}
                                                        totalNrc={totals.subTotalNrc + totals.taxNrc}
                                                    />
                                                </AvoidPageBreak>
                                                <AvoidPageBreak wrapperClass='mt-4'>
                                                    <Notes quoteID={urlQuoteID} />
                                                </AvoidPageBreak>
                                                <AvoidPageBreak wrapperClass='mt-4'>
                                                    {showPortingNumbers && <PortingTable quote={quote} />}
                                                </AvoidPageBreak>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                {showPayment && <AvoidPageBreak>
                                    <Card><PaymentInfo quoteID={urlQuoteID} formRef={paymentFormRef} buttonRef={paymentButtonRef} /></Card>
                                </AvoidPageBreak>}
                                {showTOS && <Card>
                                    <AgreementContainer header={agreements.tos.title}>
                                        <TOSAgreement />
                                    </AgreementContainer>
                                    <AgreementContainer header={agreements.elavator.title}>
                                        <ElevatorAndFireAlarm />
                                    </AgreementContainer>
                                    <AgreementContainer header={agreements.acknowledgement.title}>
                                        <CustomerAcknowledgement companyName={quote.company?.name} />
                                    </AgreementContainer>
                                    {quote.portingNumbers && isPortingNumbers.isPorting && <AgreementContainer
                                        header={agreements.authorization.title}>
                                        <AuthorizationLetter companyName={quote.company?.name}
                                            hasAdditionalNumbers={hasAdditionalNumbers}
                                            onAdditionalNumbersChange={setHasAdditionalNumbers}
                                        />
                                    </AgreementContainer>}
                                    {quote.portingNumbers && isPortingNumbers.isTollFreePorting && <AgreementContainer
                                        header={agreements.tfAuthorization.title}>
                                        <TollFreeAuthorization singlePage={false} />
                                    </AgreementContainer>}
                                </Card>}
                            </div>
                        </div>
                    </div>}
                </div>
            }
            {(!hashValidated && !isLoading) && <LinkExpired />}
            <Popup
                width={300}
                position={{ my: 'center', at: 'center', of: window, offset: '225 -15' }}
                height={140 + (popup.errorMessages.length * 60)}
                showTitle={true}
                shading={false}
                title={popup.title}
                dragEnabled={false}
                hideOnOutsideClick={true}
                showCloseButton={true}
                visible={popup.show}
                onHiding={() => setPopup({ show: false, errorMessages: [], title: '', helperText: '' })}
                contentRender={renderPopup}>
            </Popup>
            <ContractSentModal show={showContractModal}
                onClose={() => setShowContractModal(false)}
                onDownload={onDownload} />
            <SignatureModal
                show={showSignatureModal}
                onClose={() => setShowSignatureModal(false)}
                onSetSignatureCallBack={onSetSignatureCallBack} />
            <EmailPromptModal
                show={showEmailModal}
                onClose={() => setShowEmailModal(false)}
                onContinue={saveContract} />
            <QuoteErrorModal
                show={showQuoteErrorModal}
            />
            <LoadPanel
                shadingColor="rgba(0,0,0,0.4)"
                position='center'
                message={loader.message}
                visible={loader.show}
                showIndicator={true}
                showPane={true}
            />
        </>
    );
};
export default ReviewQuote;
