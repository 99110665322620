import React, { useEffect, useState } from 'react';
import DropDownBox from '../common/DropDownBox';
import { provinces, states } from '../common/States';
import TextInput from '../common/TextInput';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useReviewQuoteContext } from '../../ReviewQuoteContext.js';
import InvalidFeedback from '../common/InvalidFeedback';

const LocationInputs = ({ location, onTextChange, baseTabIndex }) => {
    const [locationStates, setLocationStates] = useState([]);
    const { countries } = useReviewQuoteContext();

    useEffect(() => {
        let result = [];
        if (location.country.toUpperCase() === 'UNITED STATES') {
            result = states;
        } else if (location.country.toUpperCase() === 'CANADA') {
            result = provinces;
        }
        setLocationStates(result.map(a => {
            a.value = a.abbreviation;
            a.text = a.name;
            return a;
        }));
    }, [location.country]);

    return (
        <div className='row'>
            <div className='col-md-6 col-12'>
                <TextInput
                    tabIndex={baseTabIndex + 1}
                    value={location.address}
                    name='address'
                    placeholder='Address'
                    required
                    onChange={onTextChange} />
                <TextInput
                    tabIndex={baseTabIndex + 3}
                    value={location.city}
                    name='city'
                    placeholder='City'
                    required
                    onChange={onTextChange} />
                <TextInput
                    tabIndex={baseTabIndex + 5}
                    value={location.zipCode}
                    name='zipCode'
                    placeholder='Zip'
                    required
                    onChange={onTextChange} />
            </div>
            <div className='col-md-6 col-12'>
                <TextInput
                    tabIndex={baseTabIndex + 2}
                    value={location.address2}
                    name='address2'
                    placeholder='Address 2'
                    onChange={onTextChange} />
                <div className='mt-5'>
                    <DropDownBox
                        options={locationStates}
                        tabIndex={baseTabIndex + 4}
                        placeholder='Select State'
                        defaultValue={location.state}
                        onChange={onTextChange}
                        disabled={!locationStates.length}
                        required
                        name='state' />
                </div>
                <div className='mt-5' onChange={(e) => onTextChange({ target: { ...e.target, name: 'country' } })}>
                    <Typeahead
                        id={`country-select-${location.addressType}`}
                        name='country'
                        filterBy={['country']}
                        labelKey='country'
                        inputProps={{ required: true, tabIndex: baseTabIndex + 6 }}
                        onChange={(e) => onTextChange({ target: { value: e.length ? e[0].country : location.country, name: 'country' } })}
                        options={countries}
                        placeholder='Select Country'
                        selected={[location.country]}
                        renderMenuItemChildren={({ country }) => <div key={country}>{country}</div>} />
                    <input type='text' className='form-control' required hidden value={location.country} onChange={() => { }} />
                    <InvalidFeedback />
                </div>
            </div>
        </div>
    );
};
export default LocationInputs;
