import React, { useEffect, useState } from 'react';
import dateFormat from 'dateformat';
import CheckBox from '../common/CheckBox';
import axios from 'axios';
import PortingTable from '../quotes/PortingTable';
import ReviewingPortingDisplay from '../quotes/ReviewingPortingDisplay';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';
import Signature from './Signature';
const AuthorizationLetter = ({ companyName, hasAdditionalNumbers, onAdditionalNumbersChange }) => {
    const getEmptyLocation = () => {
        return {
            address: '',
            city: '',
            state: '',
            zipCode: ''
        };
    };

    const [serviceLocation, setServiceLocation] = useState(getEmptyLocation());
    const [billingLocation, setBillingLocation] = useState(getEmptyLocation());
    const { generatingPDF, reviewingContract, quote, contractSigned, contact } = useReviewQuoteContext();

    useEffect(() => {
        const controller = new AbortController();
        if (quote.quoteID) {
            getQuoteLocations(controller);
        }
        return () => controller.abort();
    }, []);

    const getQuoteLocations = async (controller) => {
        const requestConfig = controller ? { signal: controller.signal } : null;
        const { data } = await axios.get(`/Api/Quotes/GetCore?route=/API/Quotes/GetQuoteLocations?quoteID=${quote.quoteID}&companyID=${quote.companyID}&addressType=null`, requestConfig);
        if (controller.signal.aborted) {
            return;
        }
        const serviceAddress = data.locations.find(l => l.companyLocation?.addressType === 'Service');
        const billingAddress = data.locations.find(l => l.companyLocation?.addressType === 'Billing');
        setServiceLocation(serviceAddress || getEmptyLocation());
        setBillingLocation(billingAddress || getEmptyLocation());
    };

    const onAdditionalNumbersCheck = (e) => {
        onAdditionalNumbersChange(e.target.checked);
    };
    return (
        <>
            <p>This Letter of Authorization (this “LOA”), by {companyName} (“Customer” or “End User”)
                is incorporated into, and made a part of, the Agreement (as defined in the Service Order dated as of {dateFormat(new Date(), 'mm/dd/yyyy')},
                between [Jivetel Inc.][Jivetel International Inc.] (“Jivetel”) and
                Customer).</p>
            <p>1. Customer Name (your name should appear EXACTLY as it does on your local telephone bill)
                First Name: {contact.firstName}, Last Name: {contact.lastName}, Business Name (required only if phone service is in your Company's Name): {companyName}</p>
            <p>2. Service Address (primary address where the telephone service will be located. No Post Office Boxes)</p>
            <p>Address: {serviceLocation.address}, City: {serviceLocation.city}, State: {serviceLocation.state}, Zip Code: {serviceLocation.zipCode}</p>
            <p>3. Billing Address (if different from your service address, should appear EXACTLY as it does on your local telephone bill)</p>
            <p>Address: {billingLocation.address}, City: {billingLocation.city}, State: {billingLocation.state}, Zip Code: {billingLocation.zipCode}</p>
            <p>4. List below all Telephone Number(s) for which you authorize change from your current phone service provider to Jivetel.
                Please note that your telephone and/or associated service(s) will be changed to Jivetel.</p>
            <ReviewingPortingDisplay tollFree={false} />
            {!generatingPDF && <CheckBox
                label='Check this box, if you have additional numbers on your Business Account that you do NOT want ported.'
                checked={hasAdditionalNumbers}
                onChange={onAdditionalNumbersCheck}
                id='additional-numbers'
                wrapperClass='mt-4 mb-4'
                disabled={contractSigned}
            />}
            <div className='avoid-page-break'>
                <h2 className='mt-3 mb-3'>Verification</h2>
                <h6>PLEASE READ BEFORE SIGNING BELOW</h6>
                <p>By signing below, I verify that I am, or represent (for a business), the above-named
                    local service customer, authorized to change the primary carrier(s) for the telephone number(s) listed,
                    and am at least 18 years of age. The name and address I have provided is the name and address on record
                    with my local telephone company for each telephone number listed. I warrant that the address that I have
                    provided above is the address where I will be using this service. I authorize and designate Jivetel to act
                    as my agent and notify my current carrier(s) to change my preferred carrier(s) for the listed number(s),
                    to obtain any information Jivetel deems necessary to make the carrier change(s), including, for example,
                    an inventory of telephone lines billed to the telephone number(s), carrier or customer identifying
                    information, billing addresses, and my credit history. I further understand that after this process is
                    completed my telephone and/or associated service(s) will be changed to Jivetel, as indicated above.
                    I understand that I am authorizing change(s) of my primary carrier(s) for my telephone and/or associated service(s),
                    and that I may select only one primary carrier per service, per number. I understand that my local telephone company
                    may bill me a one-time charge for requested service change(s) for each telephone number. If you are entering into this
                    Letter of Authorization on behalf of a company or other legal entity, you represent that you have the authority to bind
                    such company or other legal entity to this Letter of Authorization, in which case the term "I" or any variation thereof
                    shall refer to such entity. Your signature indicates your acceptance of the terms and conditions set forth in the Agreement,
                    which includes, but is not limited to, this LOA.</p>
                <Signature name='authorization' />
            </div>
        </>
    );
};
export default AuthorizationLetter;
