import React, { useState, useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'react-bootstrap/Button';

const ContractSentModal = ({ show, onClose, onDownload }) => {
    return (<>
        <Modal show={show} enforceFocus="false" className='modal-dark-bg' centered>
            <Modal.Header className='bg-primary-600'>
                <Modal.Title>Contract has been signed and sent.</Modal.Title>
                <button type="button" className="close" onClick={onClose}>×</button>
            </Modal.Header>
            <Modal.Body >
                <small className='text-muted'>Welcome to Jivetel! Someone from our onboarding team will be reaching out to discuss your account setup.</small>
                <div className='d-flex justify-content-center m-3'>
                    <div className='text-success'>
                        <FontAwesomeIcon icon="fa-solid fa-circle-check" size='5x' />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='btn btn-light btn-white border' onClick={onDownload}>
                    <span className='grey-text'><FontAwesomeIcon icon="fa-solid fa-download" /></span>
                    <span className='pl-2 grey-text'>Download All</span>
                </button>
                <button className='btn-blue small-button' onClick={onClose}><span className='text-white'>Okay</span></button>
            </Modal.Footer>
        </Modal>
    </>
    );
};
export default ContractSentModal;
