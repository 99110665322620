import React from 'react';
import InlineText from '../common/InlineText';

const CreditCardDisplay = ({ info }) => {
    return (
        <>
            <InlineText label='Name' value={info.accountHolderName} />
            <InlineText label='Card Number' value={info.creditCardNumber?.padStart(16, '*')} />
            <InlineText label='Expiration' value={`${info.creditCardExpiryMonth?.toString()?.padStart(2, 0)}/${info.creditCardExpiryYear}`} />
        </>
    );
};
export default CreditCardDisplay;
