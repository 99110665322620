import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Chart,
    Series,
    ArgumentAxis,
    ValueAxis,
    CommonSeriesSettings,
    Tooltip,
    Grid
} from 'devextreme-react/chart';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import axios from 'axios';
import CostSavings from './CostSavings';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';
import { FormatCurrency } from '../common/FormatCurrency';

const CostAnalysis = ({ totalMrc, totalNrc }) => {
    const [costAnalysis, setCostAnalysis] = useState([]);
    const { generatingPDF } = useReviewQuoteContext();
    const urlQuoteID = useParams().quoteID;

    useEffect(() => {
        getCostAnalysis();
    }, []);

    const getCostAnalysis = async () => {
        const { data } = await axios.get(`/Api/Quotes/GetCore?route=/API/Quotes/GetCostAnalysis?quoteID=${urlQuoteID}`);
        setCostAnalysis(data.list.sort((a, b) => a.cost - b.cost));
    };

    if (!costAnalysis.length) {
        return <></>;
    }

    const currentMonthlyCost = costAnalysis.reduce((a, o) => +o.cost ? a + +o.cost : a, 0);

    const getCarrierSize = () => {
        const size = { height: 225 };
        if (generatingPDF) {
            size.height = 350;
            size.width = 1300;
        }
        return size;
    };

    const getTotalSize = () => {
        const size = { height: 200 };
        if (generatingPDF) {
            size.width = 1300;
            size.height = 325;
        }
        return size;
    };

    const totals = [{ name: 'Jivetel Monthly Cost', total: totalMrc }, { name: 'Current Monthly Cost', total: currentMonthlyCost }];

    return (<>
        <h5><b>Cost Analysis</b></h5>
        <Row className='justify-content-between d-flex flex-wrap'>
            <Col xl={8} lg={6} md={12} className='p-2 pl-4'>
                <Chart id='totals' dataSource={totals} size={getTotalSize()}>
                    <Series
                        valueField='total'
                        argumentField='name'
                        name='Total'
                        type='bar'
                        color='#3963a0' />
                    <ValueAxis
                        label={{ format: 'currency' }}>
                    </ValueAxis>
                    <Tooltip format={FormatCurrency} enabled={true} />
                </Chart>
            </Col>
            {costAnalysis.length > 1 && <Col xl={8} lg={6} md={12} className='p-2 pl-4'>
                <Chart
                    dataSource={costAnalysis}
                    size={getCarrierSize()}>
                    <CommonSeriesSettings
                        color='#c70030'
                        argumentField='carrier'
                        type='line' />
                    <Series valueField='cost'
                        name='Cost' />
                    <ValueAxis
                        label={{ format: 'currency' }}>
                    </ValueAxis>
                    <ArgumentAxis
                        valueMarginsEnabled={false}
                        discreteAxisDivisionMode='crossLabels'>
                        <Grid visible={true} />
                    </ArgumentAxis>
                    <Tooltip format={FormatCurrency} enabled={true} />
                </Chart>
            </Col>}
            <Col xl={3} md={6} >
                <CostSavings currentMonthlyCost={currentMonthlyCost}
                    jivetelMonthlyCost={totalMrc}
                    jivetelInitialCost={totalNrc} />
            </Col>
        </Row>
    </>);
};

export default CostAnalysis;
