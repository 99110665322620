import React, { useEffect } from 'react';
import DropDownBox from '../common/DropDownBox';

const CustomerSelect = ({ customerNames, handleCustomerSelect, selectedCustomer }) => {
    useEffect(() => {
        if (customerNames.length === 1) {
            handleCustomerSelect(customerNames[0].value);
        }
    }, []);
    const onChange = e => {
        handleCustomerSelect(e.target.value);
    };
    return (
        <>
            <DropDownBox
                options={customerNames}
                placeholder='Select CustomerID'
                onChange={onChange}
                disabled={customerNames.length === 1}
                defaultValue={selectedCustomer}
            />
        </>
    );
};

export default CustomerSelect;
