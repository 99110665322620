import React, { useState, useEffect } from 'react';
import ActionEmails from './ActionEmails';
import CheckBox from '../common/CheckBox';

const SubscribedEmails = ({ data, handleEmailActionChecked, handleSelectAllCheckBox, customerName, customerID, notificationTypes }) => {
    const [selectAll, setSelectAll] = useState(false);
    const [customerData, setCustomerData] = useState([]);

    useEffect(() => {
        setCustomerData(data.filter(c => c.customerName === customerName));
    }, [customerName, data]);

    useEffect(() => {
        setSelectAll(false);
    }, [customerID]);

    const categories = [...new Set(data.map(d => d.category))];

    const onSelectAllCheckBox = () => {
        setSelectAll(!selectAll);
        handleSelectAllCheckBox(!selectAll);
    };
    const actionEmails = categories.filter(c => c !== 'Notification').map((c, i) => {
        const actionData = customerData.filter(d => d.category === c);
        if (actionData.length > 0) {
            return <ActionEmails key={i} action={c} data={actionData} handleEmailActionChecked={handleEmailActionChecked} />;
        }
        return '';
    });

    return (
        <>
            {customerName && <><div className="row">
                <CheckBox
                    key='selectAll'
                    label='Select All'
                    checked={selectAll}
                    id='selectAll'
                    onChange={onSelectAllCheckBox}
                    wrapperClass='col-6 text-left'
                    name='selectAll'
                />
            </div>
                <div className="row">
                    {actionEmails}
                    <ActionEmails
                        key='notifications'
                        action='Notification'
                        data={customerData.filter(d => d.action === 'Notification')}
                        handleEmailActionChecked={handleEmailActionChecked}
                        customerID={customerID}
                        notificationTypes={notificationTypes} />
                </div></>}
            {data.filter(d => d.action === 'Tickets')?.length > 0 && < div className="row">
                <ActionEmails key='tickets' action='Tickets' data={data.filter(d => d.action === 'Tickets')} handleEmailActionChecked={handleEmailActionChecked} />
            </div>}
        </>
    );
};

export default SubscribedEmails;
