import Notify from 'devextreme/ui/notify';
export const formatPhoneNumber = (phone) => {
    if (phone) {
        return phone.replace(/\D+/g, '')
            .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
    return '';
};
export const capitalizeFirstLetter = (string) => {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1);
};
export const getEmptyContact = () => {
    return {
        name: '',
        firstName: '',
        lastName: '',
        phone: '',
        mobilePhone: '',
        email: '',
        listOfCommunications: [],
        isAuthorized: false,
        isSendStatement: false,
        isHolidayReminder: false,
        quoteContactTags: []
    };
};

export const getEmptyLocation = () => {
    return {
        locationID: 0,
        name: '',
        state: '',
        addressType: '',
        address: '',
        address2: '',
        city: '',
        zipCode: '',
        country: 'United States'
    };
};
export const notify = (message, type) => {
    Notify({
        message,
        maxWidth: 500,
        displayTime: 4000,
        position: {
            my: 'center-top',
            at: 'center-top',
            offset: '23'
        },
        closeOnClick: true,
        animation: {
            show: {
                type: 'fade', duration: 300, from: 0, to: 1
            },
            hide: { type: 'fade', duration: 500, to: 0 }
        },
        type: type || 'success'
    },
        {
            position: 'top center',
            direction: 'down-push'
        });
};
export const objectMatches = (obj1, obj2) => {
    const obj1String = JSON.stringify(obj1);
    const obj2String = JSON.stringify(obj2);
    return obj1String === obj2String;
};
