import Notify from 'devextreme/ui/notify';

const Alert = (msg, type) => {
    Notify({
        message: msg,
        height: 100,
        width: 300,
        minWidth: 150,
        type: type,
        displayTime: 10000,
        animation: {
            show: {
                type: 'fade', duration: 400, from: 0, to: 1
            },
            hide: { type: 'fade', duration: 40, to: 0 }
        },
        closeOnClick: true
    }, {
        position: {
            top: 150,
            left: 0,
            right: 0
        },
        direction: 'down-push'
    });
};
export default Alert;
