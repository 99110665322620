import React from 'react';
import Card from 'react-bootstrap/Card';
import Layout from '../components/Layout';
import Header from '../components/header/Header';

const Login = () => {
  return (
        <>
            <Header />
            <div className="row my-5 justify-content-center">
                <div className="col-md-6 col-xl-5 col-12 m-2">
                    <Card className="p-3">
                        <Card.Body>
                            <Card.Title className="text-info"><u>Billing Portal</u></Card.Title>
                            <Card.Link href="https://cp.jivetel.com" target="_blank">
                                Manage your jivetel billing account here
                            </Card.Link>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-6 col-xl-5 col-12 m-2">
                    <Card className="p-3">
                        <Card.Body>
                            <Card.Title className="text-info"><u>JiveFax Portal</u></Card.Title>
                            <Card.Link href="https://login.jivefax.com/" target="_blank">
                                Manage your Jivetel JiveFax account here
                            </Card.Link>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-6 col-xl-5 col-12 m-2">
                    <Card className="p-3">
                        <Card.Body>
                            <Card.Title className="text-info"><u>PBX Portal</u></Card.Title>
                            <Card.Link href="https://portal.jivetel.net/portal/" target="_blank">
                                Manage your Jivetel PBX hosted account here
                            </Card.Link>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col-md-6 col-xl-5 col-12 m-2">
                    <Card className="p-3">
                        <Card.Body>
                            <Card.Title className="text-info"><u>SMS Portal</u></Card.Title>
                            <Card.Link href="https://sms.jivetel.com/login" target="_blank">
                                Your Jivetel SMS portal to send and recieve messages
                            </Card.Link>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </>
  );
};
export default Login;
