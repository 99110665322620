import React from 'react';

const HeaderAgreement = ({ companyName }) => {
  const today = new Date();
  const month = today.getMonth() + 1; // Add 1 because getMonth() returns a zero-based index
  const day = today.getDate();
  const year = today.getFullYear();
  return (
        <>
            <p>
                This Service Order (this “Service Order”), dated as of {month}/{day}/{year}, is between Jivetel Inc. (“Jivetel”) and {companyName} (“Customer”), and
                incorporates by reference (i) the Jivetel Terms of Service (Jivetel “TOS”), which are available at
                <a href="https://www.jivetel.com/terms-of-service"> https://www.jivetel.com/terms-of-service</a>, (ii) the E911 Customer Acknowledgement executed
                by Customer (the “E911 Customer Acknowledgement”), attached hereto, and (iii) to the extent applicable, the Letter of Authorization executed by Customer (the “LOA”),
                attached hereto, (iv) and, if applicable, the Phone Rental Agreement. This Service Order, the TOS, the E911 Customer Acknowledgement,
                the LOA and Phone Rental Agreement are collectively referred to herein as the Agreement (the “Agreement”). Any defined terms used
                herein and not otherwise defined shall have the meanings ascribed thereto in the TOS.
            </p>
        </>
  );
};
export default HeaderAgreement;
