import React, { useRef, useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import TextInput from '../common/TextInput';
import SignatureCanvas from 'react-signature-canvas';
import axios from 'axios';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';
import CheckBox from '../common/CheckBox';
import Form from 'react-bootstrap/Form';
import { useParams } from 'react-router-dom';
import Tooltip from '../common/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalizeFirstLetter } from '../../helper';

const SignatureModal = ({ show, onClose, onSetSignatureCallBack }) => {
    const [hasValidationRun, setHasValidationRun] = useState(false);
    const [signatureBase64, setSignatureBase64] = useState('');
    const signatureRef = useRef(null);
    const [tab, setTab] = useState('draw');
    const { quote, setSignedQuote, setReviewingContract, signedQuote, contact, setContact } = useReviewQuoteContext();
    const emailHash = useParams().emailHash;
    useEffect(() => {
        if (show) {
            setFont();
            if (signedQuote?.signatureBase64) {
                drawImage(signedQuote?.signatureBase64);
            }
        } else {
            loadFonts();
        }
        setTab('draw');
        setHasValidationRun(false);
        clearCanvases();
    }, [show]);

    useEffect(() => {
        if (!signatureRef?.current) {
            return;
        }
        clearCanvases();
        if (tab === 'apply') {
            signatureRef.current.off();
            if (contact.firstName || contact.lastName) {
                fillCanvases(contact);
            }
        } else {
            signatureRef.current.on();
        }
    }, [tab, signatureRef]);

    const loadFonts = async () => {
        await document.fonts.ready;
    };

    const setFont = () => {
        const canvas = signatureRef.current.getCanvas();
        const context = canvas.getContext('2d');
        context.font = '30px "Alex Brush"';
        context.fillText('', 50, 35);
    };

    const onSetSignatureClick = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!e.currentTarget.checkValidity() || !signatureBase64) {
            setHasValidationRun(true);
            return;
        }
        let contactID;
        if (!emailHash) {
            const matchingContactID = await getContactIfMatches();
            contactID = matchingContactID;
        }
        if (!contactID) {
            contactID = await saveContact(contact.contactType);
            await addQuoteContact(contactID);
            setContact({ ...contact, contactID });
        }
        const { data } = await axios.post('/Api/Quotes/PostToCore?route=/API/Quotes/SaveSignature', { signatureBase64, quoteID: quote.quoteID, contactID });
        console.log(data);
        onSetSignatureCallBack();
        setSignedQuote({ signatureBase64 });
        setReviewingContract(true);
        onClose();
    };

    const fillCanvases = (values) => {
        const { firstName, lastName } = values;
        const name = `${firstName} ${lastName}`;
        fillCanvas(signatureRef, name, 'left', onSignatureChange);
    };

    const clearCanvases = () => {
        signatureRef.current?.clear();
        setSignatureBase64('');
    };

    const fillCanvas = async (canvasRef, text, textAlign, setBase64) => {
        const canvas = canvasRef.current.getCanvas();
        const context = canvas.getContext('2d');
        context.textAlign = textAlign;
        context.fillText(text, 20, 55);
        setBase64();
    };

    const drawImage = (base64) => {
        const image = new Image();
        image.onload = () => {
            const canvas = signatureRef.current.getCanvas();
            const context = canvas.getContext('2d');
            context.drawImage(image, 0, 0, canvas.width, canvas.height);
            onSignatureChange();
        };
        image.src = base64;
        signatureRef.current.off();
    };

    const onNameChange = (e) => {
        const newContact = { ...contact, [e.target.name]: e.target.value };
        setContact(newContact);
        if (tab === 'apply') {
            clearCanvases();
            fillCanvases(newContact);
        }
    };

    const onSignatureChange = () => {
        setSignatureBase64(signatureRef.current.getTrimmedCanvas().toDataURL());
    };

    const getContactIfMatches = async () => {
        const { data } = await axios.get(`/Api/Quotes/GetCore?route=/API/Quotes/GetQuoteContacts?quoteID=${quote.quoteID}`);
        let quoteContacts = data.quoteContacts;
        const primaryIndex = quoteContacts.findIndex(qc => qc.contactID === quote.primaryContactID);
        quoteContacts.push(...quoteContacts.splice(0, primaryIndex));
        quoteContacts = quoteContacts.map(qc => qc.contact);
        const matchingContact = quoteContacts.find(contactMatches);
        if (matchingContact) {
            const listOfCommunications = matchingContact.listOfCommunications;
            setContact({
                ...matchingContact,
                email: listOfCommunications.find(c => c.communicationType === 'EmailAddress')?.value,
                phone: listOfCommunications.find(c => c.communicationType === 'PhoneNumber' || c.communicationType === 'MobileNumber')?.value
            });
            return matchingContact.contactID;
        }
    };

    const contactMatches = (existingContact) => {
        const { firstName, lastName } = existingContact;
        const firstNameMatches = trimValue(contact.firstName) === trimValue(firstName);
        const lastNameMatches = !lastName || trimValue(contact.lastName) === trimValue(lastName);
        return firstNameMatches && lastNameMatches;
    };

    const trimValue = (value) => {
        if (!value) {
            return;
        }
        return value.trim().toUpperCase().replace('.', '');
    };

    const saveContact = async (contactType) => {
        const { data } = await axios.post('/Api/Quotes/PostToCore?route=/API/Quotes/SaveContact', { ...contact, contactType });
        return data.contactID;
    };

    const addQuoteContact = async (contactID) => {
        await axios.post('/Api/Quotes/PostToCore?route=/API/Quotes/AddQuoteContact', { contactID, quoteID: quote.quoteID });
    };

    const onClearClick = () => {
        clearCanvases();
        signatureRef.current.on();
    };

    const onTabSelect = (t) => {
        setTab(t);
    };

    return (<>
        <Modal show={show} enforceFocus="false" className='modal-dark-bg' centered>
            <Modal.Body className=''>
                <p className='text-muted p-2'>
                    <small>{`Please ${emailHash ? 'confirm' : 'type'} your full name and signature.`}</small>
                    <button type="button" className="close" onClick={onClose}>×</button>
                </p>
                <div className='m-2'>
                    <Form noValidate validated={hasValidationRun} onSubmit={onSetSignatureClick} id='signature-form'>
                        <div className='row'>
                            <div className='col-md-6'>
                                <TextInput placeholder='First Name'
                                    required
                                    showLabel={false}
                                    value={contact.firstName}
                                    onChange={onNameChange}
                                    name='firstName' />
                            </div>
                            <div className='col-md-6'>
                                <TextInput placeholder='Last Name'
                                    required
                                    showLabel={false}
                                    value={contact.lastName}
                                    onChange={onNameChange}
                                    name='lastName' />
                            </div>
                        </div>
                    </Form>
                    <Tabs
                        id="signature"
                        activeKey={tab}
                        onSelect={onTabSelect}
                        className="mb-3">
                        <Tab eventKey="draw" title="Draw signature">
                            <small> Draw your signature with <span className='font-weight-bold'>your mouse, tablet or smartphone</span>
                                <Button id='clear' variant='link' className='shadow-none' onClick={onClearClick} >
                                    Clear <Tooltip message='Clear signature' target='#clear'></Tooltip>
                                </Button>
                            </small>
                        </Tab>
                        <Tab eventKey="apply" title="Apply name as signature">
                            <div className='p-3'></div>
                        </Tab>
                    </Tabs>
                    <div className='row m-2 ml-0 d-flex flex-wrap text-muted'>
                        <div className='col-md-10 col-12 d-flex border-bottom-dashed p-0'>
                            <div className='col-1 align-self-end pb-2 light-gray p-0'>
                                <FontAwesomeIcon icon='fa-times' size='2x' />
                            </div>
                            <div className='col-11 align-self-end p-0'>
                                <SignatureCanvas ref={signatureRef} onEnd={onSignatureChange}
                                    canvasProps={{ height: 70, width: 320 }} />
                            </div>
                        </div>
                    </div>
                    {(hasValidationRun && !signatureBase64) && <>
                        <div className='container'>
                            <small className='text-danger'>This is a required signature</small>
                        </div>
                    </>}
                    <div className='container mt-3'>
                        <small className='text-muted'>
                            I acknowledge that I am {contact.firstName ? `${capitalizeFirstLetter(contact.firstName)} ` : '___________ '}
                            {contact.lastName ? capitalizeFirstLetter(contact.lastName) : '___________'}.
                            Furthermore, I affirm that this digital representation serves as a valid and legally binding substitute for my signature,
                            equivalent in all respects to a signature made with a physical pen and paper, for all intents and purposes.
                        </small>
                    </div>
                </div>
                <div className='d-flex justify-content-end'>
                    <button className='btn-blue medium-button' type='submit' form='signature-form'>
                        <span className='white-text'>Set Signature</span>
                    </button>
                </div>
            </Modal.Body>
        </Modal>
    </>
    );
};
export default SignatureModal;
