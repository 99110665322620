import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InvalidFeedback from '../common/InvalidFeedback';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';

const EmailPromptModal = ({ show, onClose, onContinue }) => {
    const { contact, setContact } = useReviewQuoteContext();
    const [hasValidationRan, setHasValidationRan] = useState(false);

    useEffect(() => {
        if (!show) {
            setHasValidationRan(false);
        }
    }, [show]);

    const onSubmit = async (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!e.currentTarget.checkValidity()) {
            setHasValidationRan(true);
            return;
        }
        await addCommunication();
        onClose();
        onContinue();
    };

    const addCommunication = async () => {
        await axios.post('/Api/Quotes/PostToCore?route=/API/Quotes/AddCommunication', {
            contactID: contact.contactID,
            communicationType: 'EmailAddress',
            value: contact.email
        });
    };

    const onCloseClick = () => {
        setContact({ ...contact, email: '' });
        onClose();
    };

    return (<>
        <Modal show={show}
            enforceFocus={false}
            className='modal-dark-bg'
            onHide={onCloseClick}
            centered>
            <Form noValidate validated={hasValidationRan} onSubmit={onSubmit}>
                <Modal.Header className='bg-primary-600'>
                    <Modal.Title>Enter Your Email</Modal.Title>
                    <button type="button" className="close" onClick={onCloseClick}>×</button>
                </Modal.Header>
                <Modal.Body >
                    <small className='text-muted'>Please enter an email that the quote should be sent to</small>
                    <Row className='justify-content-center mt-3 mb-3'>
                        <Col md={10}>
                            <InputGroup>
                                <InputGroup.Text className='input-group-prepend'>
                                    <FontAwesomeIcon icon="fa-solid fa-at" />
                                </InputGroup.Text>
                                <Form.Control
                                    required
                                    type='email'
                                    name='email'
                                    placeholder='example@domain.com'
                                    onChange={(e) => setContact({ ...contact, email: e.target.value })}
                                    value={contact.email}
                                />
                                <InvalidFeedback message={contact.email ? 'Please enter a valid email' : 'This is a required field'} />
                            </InputGroup>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='default' size='sm' onClick={onCloseClick}>Close</Button>
                    <Button className='btn-green small-button' type='submit' variant='success'>
                        <span className='text-white'>Finish</span>
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    </>
    );
};
export default EmailPromptModal;
