import React from 'react';
import { Outlet } from 'react-router-dom';
import { ReviewQuoteContextComponent } from './ReviewQuoteContext';

const ReviewQuoteContextLayout = ({ prefix }) => {
    return (
        <ReviewQuoteContextComponent prefix={`/quotes/${prefix}/`}>
            <Outlet />
        </ReviewQuoteContextComponent>
    );
};

export default ReviewQuoteContextLayout;
