import React from 'react';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ArrowButton = ({ onClick, direction }) => {
    return (
        <Button size='sm' variant='outline-danger' className='btn-icon ml-2' onClick={onClick}>
            <FontAwesomeIcon icon={`fas fa-long-arrow-alt-${direction}`} />
        </Button>
    );
};
export default ArrowButton;
