import 'bootstrap/dist/css/bootstrap.min.css';
import './css/bs-borders.css';
import React from 'react';
import { Routes, Route, useSearchParams } from 'react-router-dom';
import ReviewQuote from './routes/ReviewQuote';
import Login from './routes/Login';
import PdfCoverLetter from './routes/PdfCoverLetter';
import Unsubscribe from './routes/Unsubscribe';
import Error from './routes/Error';
import ReviewQuoteContextLayout from './ReviewQuoteContextLayout';
import TollFreeAgreement from './routes/TollFreeAgreement';
import CookieConsent from 'react-cookie-consent';

const App = () => {
    const displayName = App.name;
    const [searchParams, setSearchParams] = useSearchParams();
    const generatingPDF = searchParams.get('generatingPDF') === 'true';
    return (
        <>
            <Routes>
                <Route path="/Quotes">
                    <Route element={<ReviewQuoteContextLayout prefix='reviewquote' />}>
                        <Route path="/Quotes/ReviewQuote/:quoteID/:hash" element={<ReviewQuote />} />
                        <Route path="/Quotes/ReviewQuote/:quoteID/:hash/:emailHash" element={<ReviewQuote />} />
                    </Route>
                    <Route element={<ReviewQuoteContextLayout prefix='tollfreeauthorization' />}>
                        <Route path="/Quotes/TollFreeAuthorization/:quoteID/:hash" element={<TollFreeAgreement />} />
                    </Route>
                    <Route path="/Quotes/PdfCoverLetter/:quoteID/:hash/" element={<PdfCoverLetter />} />
                </Route>
                <Route path="/Unsubscribe/:hash" element={<Unsubscribe />} />
                <Route path="/Error" element={<Error />} />
                <Route path="*" element={<Login />} />
            </Routes>
            {
                !generatingPDF && <CookieConsent
                    location="bottom" // or "top", "none", "bottom", "bottom-left", "bottom-right"
                    buttonText="Got it!"
                    cookieName="myCookieConsent"
                    style={{ background: '#2B373B' }}
                    buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
                    expires={365}> {generatingPDF}
                    We only use cookies necessary for the functionality of this site. We do not collect any personal information from users.
                </CookieConsent>
            }
        </>
    );
};
export default App;
