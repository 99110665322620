import React, { useState, useEffect } from 'react';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';

const Notes = () => {
    const { quote: { additionalNotes, company }, reviewingContract, generatingPDF } = useReviewQuoteContext();
    const taxExemptNote = 'Certain taxes have been excluded from this quote based on your tax exempt status. PLEASE NOTE: That if you do not provide us with your tax exemption certificate or other documentation confirming your exempt status you will be billed taxes and your bill will be higher than that quoted on this quote.';
    const formatNote = (quoteNote) => {
        return company?.isTaxExempt ? [quoteNote, taxExemptNote].filter(n => n).join('\n') : quoteNote || 'There are no additional notes at this time.';
    };
    return (<>
        <div>
            <h5><b>Notes</b></h5>
            <div className={(!reviewingContract && !generatingPDF) ? 'border border-fusion border-2px' : ''}>
                <p className={!reviewingContract ? 'small p-2 line-break' : 'small line-break'}>{formatNote(additionalNotes)}</p>
            </div>
        </div>
    </>);
};

export default Notes;
