import React from 'react';
import dateFormat from 'dateformat';
import Signature from './Signature';

const CustomerAcknowledgement = ({ companyName }) => {
    return (
        <>
            <p>This E911 Customer Acknowledgement (this “E911 Customer Acknowledgement”), by {companyName} (“Customer” or “End User”) is incorporated into,
                and made a part of, the Agreement (as defined in the Service Order dated as of {dateFormat(new Date(), 'mm/dd/yyyy')},
                between Jivetel Inc. (“Jivetel”) and Customer).</p>
            <p>911 & SERVICE LIMITATIONS. The Federal Communications Commission (FCC) requires that Jivetel provide E911 Service to all End Users who use
                Jivetel Services within the United States. Sections 1-7 apply to all End Users who use Jivetel Services within the United States.
                Section 8 applies to all End Users.</p>
            <p>1. 911 & SERVICE LIMITATIONS. The Federal Communications Commission (FCC) requires that Jivetel provide E911 Service to all End Users who use
                Jivetel Services within the United States. Sections 1.1-1.7 apply to all End Users who use Jivetel Services within the United States.
                Section 1.8 applies to all End Users.</p>
            <p>1.1. 911 ACKNOWLEDGEMENT AND WARNING LABELS. END USER ACKNOWLEDGES THAT JIVETEL'S EQUIPMENT AND SERVICES DO NOT SUPPORT 911 EMERGENCY
                DIALING OR OTHER EMERGENCY FUNCTIONS IN THE SAME WAY THAT
                TRADITIONAL WIRELINE 911 SERVICES WORK. THE DIFFERENCES ARE DETAILED IN THIS SECTION 1 AND END USER AGREES TO NOTIFY ANY POTENTIAL USER OF THE
                SERVICES, WHO MAY PLACE CALLS USING END USER'S SERVICES, OF
                THE 911 LIMITATIONS DESCRIBED HEREIN. JIVETEL WILL PROVIDE END USER WITH WARNING LABELS REGARDING THE LIMITATIONS OR UNAVAILABILITY OF 911 EMERGENCY DIALING.
                END USER AGREES TO PLACE A LABEL ON AND/OR NEAR
                EACH DEVICE OR OTHER CUSTOMER PREMISE EQUIPMENT ON WHICH THE SERVICES MAY BE UTILIZED. IF ADDITIONAL LABELS ARE REQUIRED, END USER MAY REQUEST THEM FROM JIVETEL.
                JIVETEL WILL PROVIDE END USER WITH AN
                ADVISORY NOTICE REGARDING 911 EMERGENCY DIALING AND REQUEST ACKNOWLEDGMENTS FROM END USER (JIVETELS E911 CUSTOMER ACKNOWLEDEMENT),
                LOCATED AT <a href='https://www.jivetel.com/e911-disclosure'>https://www.jivetel.com/e911-disclosure'</a>,
                AS PART OF THE SERVICE ORDERING PROCESS. END USER AGREES TO RESPOND AND AFFIRMATIVELY ACKNOWLEDGE THAT JIVETEL HAS ADVISED END USER OF
                THE CIRCUMSTANCES UNDER WHICH JIVETEL E911 SERVICE MAY NOT BE AVAILABLE OR MAY BE LIMITED IN COMPARISON TO TRADITIONAL 911 EMERGENCY DIALING.
                JIVETEL ADVISES END USER TO MAINTAIN AN ALTERNATIVE MEANS OF
                ACCESSING TRADITIONAL 911 SERVICES.</p>
            <p>1.2. ELECTRICAL POWER. END USER ACKNOWLEDGES THAT THE SERVICES WILL NOT FUNCTION IN THE ABSENCE OF ELECTRICAL POWER.</p>
            <p>1.3. INTERNET ACCESS. END USER ACKNOWLEDGES THAT THE SERVICES WILL NOT FUNCTION IF THERE IS AN INTERRUPTION OF END USER'S
                BROADBAND OR HIGH-SPEED INTERNET ACCESS SERVICE.</p>
            <p>1.4. NON-VOICE SYSTEMS. END USER ACKNOWLEDGES THAT THE SERVICES ARE NOT SET UP TO FUNCTION WITH OUTDIALING SYSTEMS INCLUDING HOME SECURITY SYSTEMS,
                MEDICAL MONITORING EQUIPMENT, TTY EQUIPMENT, AND ENTERTAINMENT OR SATELLITE TELEVISION SYSTEMS.
                END USER HAS NO CLAIM AGAINST JIVETEL FOR INTERRUPTION OR DISRUPTION OF SUCH SYSTEMS BY THE SERVICES.</p>
            <p>1.5. E911 SERVICE IS NOT OFFERED ON VIRTUAL NUMBERS, TOLL-FREE NUMBERS OR SIMILAR SERVICE ACCESSORIES OR ADD-ON PLANS.
                E911 SERVICE IS ONLY AVAILABLE IN SELECTED AREAS. END USERS WHO SUBSCRIBE TO JIVETEL E911
                SERVICE WILL BE REQUIRED TO REGISTER THE PHYSICAL LOCATION OF THEIR DEVICE WITH JIVETEL, BY EXECUTING THE E911 ACKNOWLEDGEMENT,
                AND AGREE TO UPDATE THE LOCATION WHENEVER THE PHYSICAL LOCATION OF SERVICE
                CHANGES BY COMPLETING THE E911 CUSTOMER ACKNOWLEDEMENT LOCATION CHANGE FORM LOCATED
                AT <a href='https://www.jivetel.com/location-change-form'>https://www.jivetel.com/location-change-form</a>.
                END USER ACKNOWLEDGES THAT JIVETEL'S ONLY MECHANISM FOR ROUTING 911
                CALLS TO THE CORRECT EMERGENCY CALL TAKER IS THE PHYSICAL LOCATION CURRENTLY REGISTERED FOR THE ACCOUNT.
                END USER ACKNOWLEDGES AND UNDERSTANDS THAT ANY ENHANCED LOCATION INFORMATION PASSED TO AN
                EMERGENCY OPERATOR BY JIVETEL WILL BE BASED UPON THE PHYSICAL LOCATION PROVIDED TO JIVETEL BY END USER.</p>
            <p>1.6. END USERS WHO ARE REQUIRED TO SUBSCRIBE TO JIVETEL E911 SERVICE WILL BE SUBJECT TO A MONTHLY E911 SERVICE CHARGE.
                THE MONTHLY E911 SERVICE FEE SHALL BE IN ADDITION TO THE APPLICABLE RESIDENTIAL OR
                BUSINESS PLAN CHARGES FOR THE ASSOCIATED LINE. THE MONTHLY CHARGE FOR JIVETEL E911 SERVICE IS ASSESSED ON A "PER-LINE" (THAT IS, PER PHONE NUMBER BASIS),
                AND WILL BE SET AT A LEVEL THAT REIMBURSES JIVETEL FOR THE
                DIRECT COSTS IT INCURS IN PROVIDING JIVETEL E911 SERVICE, INCLUDING EXPENSES JIVETEL INCURS, EITHER DIRECTLY OR INDIRECTLY,
                IN THE FORM OF STATE, COUNTY OR MUNICIPAL E911 SURCHARGES, E911 AUTOMATIC
                LOCATION INFORMATION (ALI) DATABASE STORAGE, LINE INFORMATION DATABASE AND CALLER ID (LIDB/CNAM) EXPENSES,
                AND ANY OTHER TAXES OR SURCHARGES DIRECTLY OR INDIRECTLY ASSOCIATED WITH THE PROVISION OF
                SERVICES TO END USERS SUBSCRIBING TO THIS SERVICE. JIVETEL RESERVES THE RIGHT TO ADJUST THE LEVEL OF CHARGES ASSOCIATED WITH THE PROVISION OF
                E911 SERVICES TO REFLECT INCREASES OR DECREASES IN THE COSTS IT INCURS.</p>
            <p>1.7. END USER ALSO ACKNOWLEDGES THAT JIVETEL E911 SERVICE HAS CERTAIN CHARACTERISTICS THAT DISTINGUISH IT FROM TRADITIONAL, LEGACY,
                CIRCUIT-SWITCHED 911 SERVICE. THESE CHARACTERISTICS MAY MAKE JIVETEL E911
                SERVICES UNSUITABLE FOR SOME END USERS. BECAUSE END USER CIRCUMSTANCES VARY WIDELY, END USERS SHOULD CAREFULLY EVALUATE
                THEIR OWN CIRCUMSTANCES WHEN DECIDING WHETHER TO RELY SOLELY UPON JIVETEL E911 SERVICE. END USER ACKNOWLEDGES THAT IT IS END USER'S
                RESPONSIBILITY TO DETERMINE THE TECHNOLOGY OR COMBINATION OF TECHNOLOGIES BEST SUITED TO MEET END USER'S EMERGENCY CALLING NEEDS, AND TO MAKE THE
                NECESSARY PROVISIONS FOR ACCESS TO EMERGENCY CALLING SERVICES (SUCH AS MAINTAINING A CONVENTIONAL LANDLINE PHONE OR WIRELESS
                PHONE AS A BACKUP MEANS OF COMPLETING EMERGENCY CALLS). THE FOLLOWING
                CHARACTERISTICS DISTINGUISH JIVETEL E911 SERVICE FROM TRADITIONAL, LEGACY, CIRCUIT-SWITCHED 911 SERVICE:</p>
            <p>** JIVETEL E911 SERVICE WILL NOT FUNCTION IF END USER'S DEVICE FAILS OR IS NOT CONFIGURED CORRECTLY OR IF END USER'S JIVETEL SERVICE
                IS NOT FUNCTIONING FOR ANY REASON, INCLUDING, BUT NOT LIMITED TO, ELECTRICAL
                POWER OUTAGE, BROADBAND SERVICE OUTAGE, OR SUSPENSION OR DISCONNECTION OF SERVICE BECAUSE OF BILLING OR OTHER ISSUES.
                IF THERE IS A POWER OUTAGE, END USER MAY BE REQUIRED TO RESET OR RECONFIGURE THE
                EQUIPMENT BEFORE BEING ABLE TO USE THE JIVETEL SERVICE, INCLUDING FOR E911 PURPOSES.</p>
            <p>** AFTER INITIAL ACTIVATION OF THE E911 SERVICE, AND FOLLOWING ANY CHANGE OF AND UPDATE TO END USER'S PHYSICAL LOCATION,
                THERE MAY BE SOME DELAY BEFORE THE AUTOMATIC NUMBER AND LOCATION INFORMATION IS
                PASSED TO THE LOCAL EMERGENCY SERVICE OPERATOR. THIS INFORMATION IS TYPICALLY POPULATED INTO OUR E911 DATABASES PRIOR TO SERVICE ACTIVATION,
                BUT NO GUARANTEE CAN BE MADE THAT THE AUTOMATIC NUMBER AND LOCATION INFORMATION WILL BE ACTIVATED WITHIN THIS SCHEDULE.</p>
            <p>** THE LOCAL EMERGENCY SERVICE OPERATOR RECEIVING JIVETEL E911 EMERGENCY SERVICE CALLS MAY NOT HAVE A SYSTEM CONFIGURED FOR E911 SERVICES
                OR BE ABLE TO CAPTURE AND/OR RETAIN AUTOMATIC NUMBER OR LOCATION INFORMATION. THIS MEANS THAT THE OPERATOR MAY NOT KNOW THE PHONE NUMBER
                OR PHYSICAL LOCATION OF THE PERSON WHO IS MAKING THE JIVETEL E911 CALL. DUE TO TECHNICAL FACTORS IN NETWORK DESIGN,
                AND IN THE EVENT OF NETWORK CONGESTION ON THE JIVETEL NETWORK, THERE IS A POSSIBILITY THAT A JIVETEL 911 CALL WILL PRODUCE A BUSY SIGNAL
                OR WILL EXPERIENCE UNEXPECTED ANSWERING WAIT TIMES AND/OR TAKE LONGER TO ANSWER THAN 911 CALLS PLACED VIA TRADITIONAL, LEGACY, CIRCUIT-SWITCHED TELEPHONE NETWORKS.</p>
            <p>** IF END USER DOES NOT CORRECTLY IDENTIFY THE ACTUAL LOCATION WHERE THE DEVICE WILL BE LOCATED AT THE TIME OF ACTIVATION OF THE SERVICE,
                JIVETEL E911 COMMUNICATIONS MAY NOT BE DIRECTED TO THE CORRECT LOCAL EMERGENCY OPERATOR.</p>
            <p>If you are entering into this E911 Customer Acknowledgement on behalf of a company or other legal entity, you represent that you have the authority
                to bind such company or other legal entity to this E911 Customer Acknowledgement.</p>
            <p>Your signature indicates your acceptance of the terms and conditions set forth in the Agreement, which includes,
                but is not limited to, this E911 Customer Acknowledgement.</p>
            <Signature name='acknowledgement' />
        </>
    );
};
export default CustomerAcknowledgement;
