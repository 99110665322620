import React, { useState, useEffect } from 'react';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';
import axios from 'axios';
import Table from 'react-bootstrap/Table';
import { formatPhoneNumber } from '../../helper';

const ReviewingPortingDisplay = ({ tollFree }) => {
    const [portingNumbers, setPortingNumbers] = useState([]);
    const { quote } = useReviewQuoteContext();

    useEffect(() => {
        const controller = new AbortController();
        if (quote.quoteID) {
            getPreQ(controller);
        }
        return () => controller.abort();
    }, [quote]);

    const getPreQ = async (controller) => {
        let allPreQ = [];
        const requestConfig = controller ? controller.signal : null;
        const { data: quoteDIDs } = await axios.get(`/Api/Quotes/GetCore?route=/API/Quotes/GetPortingDIDs?quoteID=${quote.quoteID}&tollFree=${tollFree}`, requestConfig);
        allPreQ = quoteDIDs.preQ;
        if (quote.projectID) {
            const { data: projectDIDs } = await axios.get(`/Api/Quotes/GetCore?route=/API/Quotes/GetPortingDIDs?projectID=${quote.projectID}&tollFree=${tollFree}`, requestConfig);
            allPreQ = [...allPreQ, ...projectDIDs.preQ];
        }
        if (controller.signal.aborted) {
            return;
        }
        setPortingNumbers(allPreQ?.map(p => {
            p.switchName = p.switch === 8 ? 2 : 1;
            return p;
        }));
    };

    const switchTypes = (name) => {
        if (name === 1) {
            return 'Voice';
        } else {
            return 'Fax';
        }
    };

    return (<>
        <div>
            <h5> <b>Porting Numbers</b></h5>
            <Table borderless className='mb-0'>
                <thead className='border-bottom'>
                    <tr className='gray-text bg-gray-100 '>
                        <th className='font-weight-normal text-left'>Numbers</th>
                        <th className='font-weight-normal'>Service</th>
                        <th className='font-weight-normal'>Current Carrier</th>
                        <th className='font-weight-normal'>Account Number</th>
                        <th className='font-weight-normal'>Auth Name</th>
                        <th className='font-weight-normal'>Notes</th>
                    </tr>
                </thead>
                <tbody className='semi-bold border-bottom'>
                    {portingNumbers.map(p => {
                        return <tr key={p.rowID}>
                            <td>{formatPhoneNumber(p.did)}</td>
                            <td>{switchTypes(p.switchName)}</td>
                            <td>{p.currentProvider}</td>
                            <td>{p.accountNumber}</td>
                            <td>{p.name}</td>
                            <td>{p.notes}</td>
                        </tr>;
                    })}
                </tbody>
                <tfoot>
                    <tr>
                        <td className='gray-text'>{portingNumbers.length} Total</td>
                    </tr>
                </tfoot>
            </Table>
        </div>
    </>);
};
export default ReviewingPortingDisplay;
