import React, { useEffect } from 'react';
import { Stepper, Step, StepLabel } from '@mui/material';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';

const QuoteProgressBar = () => {
    const { reviewingContract, preApproval, contractSigned, activeStep, setActiveStep } = useReviewQuoteContext();

    useEffect(() => {
        let result;
        if (preApproval) {
            result = 0;
        } else if (!reviewingContract) {
            result = 1;
        } else if (reviewingContract && !contractSigned) {
            result = 3;
        } else if (contractSigned) {
            result = 4;
        }
        setActiveStep(result);
    }, [preApproval, reviewingContract, contractSigned]);

    const steps = ['Review', 'Fill out info', 'Adopt signature', 'Sign the quote'];

    return (<>
        <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
                <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                </Step>
            ))}
        </Stepper>
    </>);
};
export default QuoteProgressBar;
