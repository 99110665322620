import React, { useEffect, useRef, useState } from 'react';
import { useReviewQuoteContext } from '../../ReviewQuoteContext';
import dateFormat from 'dateformat';
import Tooltip from '../common/Tooltip';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const Signature = ({ name, singlePage }) => {
    const [signature, setSignature] = useState('');
    const { generatingPDF, reviewingContract, quote, contractSigned, signedQuote, agreements, setAgreements, targetSignatureSlot, setTargetSignatureSlot } = useReviewQuoteContext();
    const agreementRef = agreements[name].ref;

    useEffect(() => {
        const displaySignature = contractSigned && !singlePage;
        if (displaySignature) {
            setSignature(signedQuote.signatureBase64);
        }
    }, [quote.quoteID, reviewingContract, generatingPDF]);

    const onSignatureClick = () => {
        if (contractSigned) {
            return;
        }
        const signatureText = signature ? '' : signedQuote.signatureBase64;
        setSignature(signatureText);
        markAgreementSigned(!!signatureText);
    };

    const markAgreementSigned = (signed) => {
        setAgreements({ ...agreements, [name]: { ...agreements[name], signed } });
        if (signed) {
            setTimeout(() => scrollToNextAgreement(name), 350);
            setTargetSignatureSlot(targetSignatureSlot + 1);
        }
    };

    const scrollToNextAgreement = (key) => {
        const properties = Object.keys(agreements);
        const nextSlot = properties.indexOf(key) + 1;
        const targetAgreement = properties[nextSlot];
        agreements[targetAgreement]?.ref?.current?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    };

    const date = signedQuote && signedQuote.dateSigned ? signedQuote.dateSigned : new Date();

    const id = `${name}-signature`;

    const showBorder = !signature && !contractSigned && !generatingPDF && !singlePage;

    return (<>
        <div className='avoid-page-break'>
        <Row className={`flex-wrap ${singlePage ? 'pl-3' : ''}`}>
            <Col id={id} xs={8} md={singlePage ? 5 : 4} className={`mt-3 ${singlePage ? 'align-self-end' : 'border-bottom  ml-2'}${showBorder ? ' border border-danger' : ''}`} onClick={onSignatureClick}>
                <Row>
                    {singlePage && <div className='h6 label my-0'>Signature:</div>}
                    {!singlePage && <Col sm={12}>
                        <label className='label' ref={agreementRef} >Sign Here</label>
                    </Col>}
                    <Col sm={singlePage ? null : 12} className={`${(!signature && contractSigned) ? 'mt-2' : ''} ${singlePage ? 'border-bottom  ml-2' : ''}`}>
                        {signature && <img src={signature} />}
                    </Col>
                </Row>
                <Tooltip message={signature ? 'Signature applied!' : 'Click here to apply your signature'}
                    target={contractSigned ? '' : `#${id}`}></Tooltip>
            </Col>
            <Col xs={8} md={{ span: singlePage ? 5 : 4, offset: singlePage ? 1 : 1 }} className={`${singlePage ? 'align-self-end' : 'border-bottom'}  mt-3`}>
                <Row>
                    {!singlePage && <Col sm={12}>
                        <label className='label'>Date</label>
                    </Col>}
                    {singlePage && <div className='h6 label my-0 align-self-end'>Date:</div>}
                    <Col sm={singlePage ? null : 12} className={`justify-content-end d-flex flex-column ${singlePage ? 'border-bottom ml-2' : ''}`}>
                        <div className={`mt-2 pb-0 ${singlePage ? 'pt-4' : ''}`}>
                            <p>{dateFormat(date, 'mm/dd/yyyy')}</p>
                        </div>
                    </Col>
                </Row>
            </Col>
            </Row>
        </div>
    </>
    );
};
export default Signature;
