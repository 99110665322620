import React from 'react';
import { formatPhoneNumber, capitalizeFirstLetter } from '../../helper';
import TextDisplay from '../common/TextDisplay';

const FullCompanyDisplay = ({ company }) => {
    return (
        <>
            <div className='col-lg-5 col-12 order-0'>
                <dl>
                    <TextDisplay
                        value={capitalizeFirstLetter(company.companyName)}
                        label='Company Name' />
                    <TextDisplay
                        value={company.serviceAddress || 'N/A'}
                        label='Service Address' />
                </dl>
            </div>
            <div className='col-lg-4 col-12 order-lg-1 order-1'>
                <dl>
                    <TextDisplay
                        value={formatPhoneNumber(company.companyPhone) || 'N/A'}
                        label='Company Phone' />
                    <TextDisplay
                        value={formatPhoneNumber(company.faxNumber) || 'N/A'}
                        label='Fax Number' />
                </dl>
            </div>
            <div className='col-lg-3 col-12 order-lg-2 order-2'>
                <dl>
                    <TextDisplay
                        value={company.website || 'N/A'}
                        label='Website' />
                </dl>
            </div>
        </>
    );
};
export default FullCompanyDisplay;
