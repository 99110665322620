import React from 'react';
import InlineText from '../common/InlineText';

const ACHDisplay = ({ info }) => {
    return (
        <>
            <InlineText label='Name' value={info.accountHolderName} />
            <InlineText label='Account Number' value={info.accountNumber} />
            <InlineText label='Routing Number' value={info.routingNumber} />
        </>
    );
};
export default ACHDisplay;
