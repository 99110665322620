import React from 'react';
import { FormatCurrency } from '../common/FormatCurrency';

const Totals = ({ subTotal, tax, totalHeader }) => {
    return (<>
        <div className='row'>
            <div className='col-lg-8 col-xl-6 col-12 ml-auto'>
                {/* <div className='d-flex flex-row justify-content-end'> */}
                {/*    <h6 className='col text-right'>Subtotal</h6> */}
                {/*    <h6 className='col-4 col-xl-3 text-right pr-0'><b>{FormatCurrency(subTotal)}</b></h6> */}
                {/* </div> */}
                {/* <div className='d-flex flex-row justify-content-end'> */}
                {/*    <h6 className='col text-right'>Estimated Taxes and Surcharges</h6> */}
                {/*    <h6 className='col-4 col-xl-3 text-right pr-0'><b>{FormatCurrency(tax)}</b></h6> */}
                {/* </div> */}
                <div className='d-flex flex-row justify-content-end'>
                    <h6 className='col text-right'>{totalHeader}</h6>
                    <h6 className='col-4 col-xl-3 text-right pr-0'><b>{FormatCurrency(subTotal + tax)}</b></h6>
                </div>
            </div>
        </div>
    </>);
};
export default Totals;
