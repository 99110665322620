import React, { useEffect, useState } from 'react';
import ConditionalWrapper from '../ConditionalWrapper';
import './/css/jivetelNav.css';

const JivetelNav = ({ pageSection }) => {
  const [pageTop, setPageTop] = useState(true);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.pageYOffset === 0) {
        setPageTop(true);
      } else {
        setPageTop(false);
      }
    });
  });

  return (
        <>
        <ConditionalWrapper
                condition={pageSection === 'header'}
                wrapper={children => <div className="sticky-top" id="topwrapper" style={!pageTop ? { borderBottom: '3px solid #3a60a3' } : {}}>
                                    <div className="top">
                        <div className="logo" style={!pageTop ? { width: '8%', margin: '5px 0 9px 0' } : {}}>
                                            <a href="https://www.jivetel.com">
                                                <img className="Logo" src={require('../../images/logo.png')} itemProp="image" width="100%" alt="Jivetel" />
                                            </a>
                                        </div>
                                        <div id="navwrapper">
                                        <a href="#nav" className="menu" id="toggle">MENU</a>
                                            <div role="navigation">{children}
                                            </div>
                                        </div>
                                    </div>
                                </div>}
            >
                <div id="nav" className="nav-collapse">
                        <ConditionalWrapper
                            condition={pageSection === 'header'}
                            wrapper={children => <ul className="nav">{children}</ul>}
                        >
                        <ConditionalWrapper
                            condition={pageSection === 'footer'}
                            wrapper={children => <ul>{children}</ul>}
                        >
                                <li className="first"><a href="https://www.jivetel.com/" title="Home">Home</a></li>
                                <li className="drop"><a title="About Jivetel">Company</a>
                                    <ul className="dropdown">
                                        <li><a title="About Jivetel" href="https://www.jivetel.com/about">About</a></li>
                                        <hr />
                                        <li><a title="Jivetel Testimonials" href="https://www.jivetel.com/testimonials" >Testimonials</a></li>
                                    </ul>
                                </li>
                                <li className="drop"><a title="Jivetel Solutions">Solutions</a>
                                    <ul className="dropdown">
                                        <li><a title="Jivetel Advantages" href="https://www.jivetel.com/solutions">Advantages</a></li>
                                        <hr />
                                        <li><a title="Jivetel Benefits" href="https://www.jivetel.com/solutions#benefits">Benefits</a></li>
                                        <hr />
                                        <li><a title="Jivetel Mobile" href="https://www.jivetel.com/mobile">Mobile</a></li>
                                        <hr />
                                        <li><a title="Jivetel Residential" href="https://www.jivetel.com/residential">Residential</a></li>
                                    </ul>
                        </li>
                        </ConditionalWrapper>
                        <ConditionalWrapper
                            condition={pageSection === 'footer'}
                            wrapper={children => <ul>{children}</ul>}
                        >
                                <li className="less"><a href="https://www.jivetel.com/features" title="Jivetel Features">Features</a></li>
                                <li style={{ padding: '0px' }}><a href="https://www.jivetel.com/resources" title="Jivetel Resources">Resources</a></li>
                                <li className="drop">
                                    <ul className="dropdown">
                                        <li><a title="Jivetel FAQ" href="https://www.jivetel.com/faq">FAQ</a></li>
                                        <hr />
                                        <li><a title="Jivetel Guides & Manuals" href="https://jivetel.atlassian.net/wiki/spaces/JivetelPublic/overview">Guides & Manuals</a></li>
                                        <hr />
                                        <li><a title="Jivetel International Rates" href="https://www.jivetel.com/rates">International Rates</a></li>
                                        <hr />
                                        <li><a title="Jivetel Software/Downloads" href="https://www.jivetel.com/software" >Software/Downloads</a></li>
                                        <hr />
                                        <li><a title="Jivetel Support Center" href="https://jivetel.atlassian.net/wiki/spaces/JivetelPublic/overview">Support Center</a></li>
                                        <hr />
                                        <li><a title="Jivetel Videos" href="https://www.youtube.com/user/jivetel">Videos</a></li>
                                    </ul>
                        </li>
                        </ConditionalWrapper>
                        <ConditionalWrapper
                            condition={pageSection === 'footer'}
                            wrapper={children => <ul>{children}</ul>}
                        >
                                <li className="drop"><a title="Legal Jivetel">Legal</a>
                                    <ul className="dropdown">
                                        <li><a title="Jivetel E911 Disclosure" href="https://www.jivetel.com/e911-disclosure" >E911 Disclosure</a></li>
                                        <hr />
                                        <li><a title="Jivetel Equipment Rental Agreement" href="https://www.jivetel.com/equipment-rental-agreement">Equipment Rental Agreement</a></li>
                                        <hr />
                                        <li><a title="Jivetel Included In Plan" href="https://www.jivetel.com/included-in-plan">Included In Plan</a></li>
                                        <hr />
                                        <li><a title="Jivetel Privacy" href="https://www.jivetel.com/privacy">Privacy</a></li>
                                        <hr />
                                        <li><a title="Jivetel Service Fees" href="https://www.jivetel.com/service-fees">Service Fees</a></li>
                                        <hr />
                                        <li><a title="Jivetel Software Terms Of Use" href="https://www.jivetel.com/software-terms-of-use">Software Terms Of Use</a></li>
                                        <hr />
                                        <li><a title="Jivetel Terms Of Service" href="https://www.jivetel.com/terms-of-service">Terms Of Service</a></li>
                                    </ul>
                        </li>
                        </ConditionalWrapper>
                        <ConditionalWrapper
                            condition={pageSection === 'footer'}
                            wrapper={children => <ul>{children}</ul>}
                        >
                                <li className="drop last"><a title="Contact Jivetel">Contact</a>
                                    <ul className="dropdown">
                                        <li><a title="Jivetel Locations" href="https://www.jivetel.com/contact" >Locations</a></li>
                                        <hr />
                                        <li><a title="Contact Jivetel" href="https://www.jivetel.com/contact#bot">Contact Us</a></li>
                                        <hr />
                                        <li><a title="Jivetel Careers" href="https://www.jivetel.com/careers">Careers</a></li>
                                    </ul>
                        </li>
                        </ConditionalWrapper>
                        <li className={`mobile quote ${pageSection === 'footer' ? 'd-none' : ''}`}><a href="https://www.jivetel.com/request-quote" title="Request a Quote">Request A Quote</a></li>
                        <li className={`mobile login ${pageSection === 'footer' ? 'd-none' : ''}`}><a href="https://www.jivetel.com/login" target="_blank" title="Login" rel="noreferrer">Login</a></li>
                        <li className={`mobile support ${pageSection === 'footer' ? 'd-none' : ''}`}><a href="https://jivetel.atlassian.net/wiki/spaces/JivetelPublic" target="_blank" title="Support" rel="noreferrer">Support</a></li>
                        <li className={`mobile phone ${pageSection === 'footer' ? 'd-none' : ''}`}><a href="tel:+18554445483">(855) 444-JIVE (5483)</a></li>
                        </ConditionalWrapper >
                            <div className="clear"></div>
                            <div className="clear"></div>
                </div >
            </ConditionalWrapper>
        </>
  );
};

export default JivetelNav;
