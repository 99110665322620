import React from 'react';
import './/css/topNavWrapper.css';

const TopNavWrapper = () => {
  return (
        <div id="topnavwrapper" className="w-100">
            <div className="topnav">
                <ul className="nav">
                    <li className="quote"><a href="https://www.jivetel.com/request-quote" title="Request A Quote">Request A Quote</a></li>
                    <li className="login"><a href="https://www.jivetel.com/login" target="_blank" title="Login" rel="noreferrer">Login</a></li>
                    <li className="support"><a href="https://jivetel.atlassian.net/wiki/spaces/JivetelPublic" target="_blank" title="Support" rel="noreferrer">Support</a></li>
                    <li className="phone"><a href="tel:+18554445483">(855) 444-JIVE (5483)</a></li>
                </ul>
                <div className="clear"></div>
            </div>
        </div>
  );
};

export default TopNavWrapper;
